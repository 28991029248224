$color_1: #fff;
$color_2: #393939;
$color_3: #757575;
$color_4: #ababab;
$color_5: #332c27;
$color_6: #ededed;
$font-family_1: "Mulish", sans-serif;
$font-family_2: "Mulish", sans-serif;
$background-color_2: #fff;
$background-color_4: #000;
$background-color_5: transparent;
$background-color_6: #757575;
$background-color_8: #ababab;
$border-color_2: #757575;
$border-color_3: #ababab;
$border-color_4: #eaeaea;
$border-color_6: transparent;
$border-color_7: #332c27;

@media (min-width: 768px) {
    .badge-new-pdp {
        width: 50px;
        height: 50px;
        line-height: 44px;
    }

    .badge-sale-pdp {
        width: 50px;
        height: 50px;
        line-height: 44px;
    }

    .mobile-only {
        display: none;
    }

    .type-hero {
        font-size: 73px;
        font-size: 4.5625rem;
        letter-spacing: -0.6px;
    }

    .type-hero-banner-heading {
        font-size: 61px;
        font-size: 3.8125rem;
    }

    .type-hero-banner-sub-heading {
        font-size: 35px;
        font-size: 2.1875rem;
    }

    .type-asset-tile-heading-inner {
        font-size: 31px;
        font-size: 1.9375rem;
        font-size: 45px;
        font-size: 2.8125rem;
    }

    .type-asset-tile-heading-outer {
        font-size: 21px;
        font-size: 1.3125rem;
        font-size: 23px;
        font-size: 1.4375rem;
    }

    .icon.logo {
        width: 125px;
        height: 50px;
    }

    .button.large {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    .buttonstyle.large {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    button.large {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    input[type="button"].large {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    #add-to-cart {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    .button-fancy-large {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    .form-horizontal {
        .field-wrapper {
            width: 50%;
        }

        .form-caption {
            font-size: 12px;
            font-size: 0.75rem;
        }
    }

    .form-indent {
        margin-left: 27%;

        .field-wrapper {
            width: 80%;
            padding-left: 0;
        }
    }

    .form-field-tooltip {
        line-height: 3.25em;
    }

    .quantity-module {
        select {
            height: 35px;
        }
    }

    #wrapper.menu-active {
        left: 320px;
    }

    .primary-content {
        position: relative;
        float: right;
        box-sizing: border-box;
        width: calc(100% - 230px);
        padding: 0 0 20px;
    }

    .primary-focus {
        .primary-content {
            float: left;
            width: 76%;
            border: none;
            margin: 0;
        }

        #secondary {
            float: left;
            box-sizing: border-box;
            width: 24%;
            padding-left: 0.4rem;
            border: none;
            background: none;
        }
    }

    .full-width {
        .primary-content {
            width: 100%;
            border: none;
            margin-left: 0;
        }
    }

    .page-content {
        .primary-content {
            padding-left: 3%;
        }
    }

    #secondary {
        box-sizing: border-box;
        position: relative;
        float: left;
        width: 230px;

        nav {
            padding-right: 1.25rem;

            a {
                display: block;
            }
        }
    }

    nav[role="navigation"] {
        left: -20rem;
        width: 20rem;
    }

    .account-menu-toggle {
        margin-right: 5%;
    }

    .menu-toggle {
        margin-right: 5%;
    }

    .navigation-mobile-backing {
        left: -20rem;
        width: 20rem;
    }

    .phrase-suggestions {
        border-top: none;
    }

    .product-suggestions {
        border-top: none;
    }

    #mini-cart {
        top: 24px;
    }

    .add-to-cart-dialog.ui-dialog {
        .ui-dialog-content {
            padding: 1.25rem;
        }

        .mini-cart-products {
            width: 50%;
            float: left;
            margin-bottom: 1.25rem;

            .mini-cart-product {
                .mini-cart-image {
                    width: 22%;
                }
            }

            .mini-cart-product-details {
                padding-right: 1.25rem;
            }
        }

        .mini-cart-totals {
            width: 50%;
            float: left;

            .mini-cart-subtotals {
                float: left;
                padding: 0 0 1.25rem;

                .select-bonus {
                    float: right;
                    margin-right: 0.625rem;
                }
            }

            .atc-actions {
                .close-modal-div {
                    width: calc(50% - 10px);
                    float: left;
                    margin-right: 10px;

                    button {
                        padding: 0;
                    }
                }

                .mini-cart-link-checkout {
                    width: calc(50% - 10px);
                    float: left;
                    clear: none;
                    margin: 0;
                    padding: 0;
                    line-height: 36px;
                }
            }
        }

        .desktop-tablet {
            clear: left;
        }
    }

    .top-banner {
        padding-top: 1.5625rem;
        padding-bottom: 0.9375rem;
    }

    .top-banner.simplified {
        .simplified-mini-cart {
            top: 32px;
        }
    }

    .primary-logo {
        background: url("../images/svg-icons/logo-tablet.svg") no-repeat;
        background-size: contain;
        height: 38px;
        width: 215px;
        top: -3px;
    }

    .header-search {
        width: calc(100% - 20px);
        margin: 1.25rem auto 0;
    }

    .h1 {
        font-size: 41px;
        font-size: 2.5625rem;
        margin: 0 0 0.875rem;
    }

    h1 {
        font-size: 41px;
        font-size: 2.5625rem;
        margin: 0 0 0.875rem;
    }

    .h2 {
        font-size: 31px;
        font-size: 1.9375rem;
    }

    h2 {
        font-size: 31px;
        font-size: 1.9375rem;
    }

    .h3 {
        font-size: 25px;
        font-size: 1.5625rem;
    }

    h3 {
        font-size: 25px;
        font-size: 1.5625rem;
    }

    .h4 {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    h4 {
        font-size: 21px;
        font-size: 1.3125rem;
    }

    #cart-table {
        border-top: 0;

        td {
            display: table-cell;
            float: none;
            width: auto;
            padding: 0.625rem;

            &:last-of-type {
                padding-right: 0;
            }
        }

        .item-image {
            float: none;
            padding: 1.25rem 0 1.875rem;
            width: 16%;
        }

        .item-details {
            padding: 1.25rem 0 1.875rem;
            padding-right: 1.875rem;
            width: 35%;
            padding-left: 1.875rem;
        }

        .item-dashboard {
            width: 38%;
        }

        .item-delivery-options {
            width: 8.75rem;

            .instore-delivery {
                .form-row {
                    margin-left: 0;
                }

                .selected-store-address {
                    margin-left: 0;
                }

                .selected-store-availability {
                    margin-left: 0;
                }
            }
        }

        thead {
            display: table-header-group;
        }

        .item-total {
            font-size: 1em;
            text-align: right;
            padding: 1.25rem 0 1.875rem;
            padding-left: 0;
            width: 10%;
        }

        .item-price {
            padding-top: 1.25rem;
            padding-right: 2.5rem;
            width: 23%;
            padding-left: 0;
        }

        .item-quantity {
            padding: 1.25rem 0 1.875rem;
            padding-right: 1.875rem;
            padding-left: 1.875rem;
            width: 16%;
        }

        .esp-last-chance {
            .item-details {
                padding-left: 1.25rem;
            }
        }
    }

    .account-module {
        .item-list {
            border-top: 0;

            td {
                display: table-cell;
                float: none;
                width: auto;
                padding: 0.625rem;

                &:last-of-type {
                    padding-right: 0;
                }
            }

            .item-image {
                float: none;
                padding: 1.25rem 0 1.875rem;
                width: 16%;
            }

            .item-details {
                padding: 1.25rem 0 1.875rem;
                padding-right: 1.875rem;
                width: 35%;
                padding-left: 1.875rem;
            }

            .item-dashboard {
                width: 38%;
            }

            .item-delivery-options {
                width: 8.75rem;

                .instore-delivery {
                    .form-row {
                        margin-left: 0;
                    }

                    .selected-store-address {
                        margin-left: 0;
                    }

                    .selected-store-availability {
                        margin-left: 0;
                    }
                }
            }

            thead {
                display: table-header-group;
            }

            .item-total {
                font-size: 1em;
                text-align: right;
                padding: 1.25rem 0 1.875rem;
                padding-left: 0;
                width: 10%;
            }

            .item-price {
                padding-top: 1.25rem;
                padding-right: 2.5rem;
                width: 23%;
                padding-left: 0;
            }

            .item-quantity {
                padding: 1.25rem 0 1.875rem;
                padding-right: 1.875rem;
                padding-left: 1.875rem;
                width: 16%;
            }

            .esp-last-chance {
                .item-details {
                    padding-left: 1.25rem;
                }
            }
        }

        .manage-password {
            .button {
                width: 18.75rem;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .product-table {
        td {
            display: table-cell;
            float: none;
            width: auto;
            padding: 0.625rem;

            &:last-of-type {
                padding-right: 0;
            }
        }

        .item-image {
            float: none;
            width: auto;
            padding-left: 0.625rem;
            padding-right: 0;
        }

        .item-details {
            width: 32%;
        }

        .item-dashboard {
            width: 38%;
        }
    }

    .pt_gift-registry {
        .item-list {
            td {
                display: table-cell;
                float: none;
                width: auto;
                padding: 0.625rem;

                &:last-of-type {
                    padding-right: 0;
                }
            }

            .item-image {
                float: none;
                width: auto;
                padding-left: 0.625rem;
                padding-right: 0;
            }

            .item-details {
                width: 32%;
            }

            .item-dashboard {
                width: 38%;
            }
        }

        #main.registered {
            .account-nav-registered {
                margin: 0 0 1.5625rem;
            }
        }

        .has-tooltip {
            .field-wrapper {
                width: 50%;
            }
        }

        .form-row-button {
            button {
                width: 17.75rem;
                padding: 0;
            }
        }

        button[name$="login_unregistered"] {
            width: 18.75rem;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_register"] {
            width: 300px;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_login"] {
            width: 15rem;
            padding-left: 0;
            padding-right: 0;
        }

        .login-rememberme {
            margin: 0 0 0 1em;
        }
    }

    .pt_wish-list {
        .item-list {
            td {
                display: table-cell;
                float: none;
                width: auto;
                padding: 0.625rem;

                &:last-of-type {
                    padding-right: 0;
                }
            }

            .item-image {
                float: none;
                width: auto;
                padding-left: 0.625rem;
                padding-right: 0;
            }

            .item-details {
                width: 32%;
            }

            .item-dashboard {
                width: 38%;
            }
        }

        #main.registered {
            .account-nav-registered {
                margin: 0 0 1.5625rem;
            }
        }

        .wishlist-shipping {
            .form-row {
                button {
                    width: inherit;
                }
            }
        }

        .form-row-button {
            button {
                width: 17.75rem;
                padding: 0;
            }
        }

        button[name$="login_unregistered"] {
            width: 18.75rem;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_register"] {
            width: 300px;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_login"] {
            width: 15rem;
            padding-left: 0;
            padding-right: 0;
        }

        .login-rememberme {
            margin: 0 0 0 1em;
        }
    }

    .nav {
        .toggle {
            margin: 1.25rem 0 0.625rem;
        }
    }

    .pt_customer-service {
        .nav {
            nav {
                border-top: 1px solid #ababab;
                margin: 1.25rem 0;
            }
        }

        #main.registered {
            .account-nav-registered {
                margin: 0 0 1.5625rem;
            }
        }

        .breadcrumb {
            margin-bottom: 0.9375rem;
        }
    }

    .footer-container {
        display: -webkit-box;
        display: flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        padding: 0 0.9375rem;

        .footer-item {
            border: 0;
            width: calc(33.3333% - 4.8542rem);

            h3 {
                line-height: 19px;
                line-height: 1.1875rem;
                margin-bottom: 0.25rem;
                padding: 0;
            }

            h3.toggle {
                &::after {
                    content: none;
                }
            }

            .note {
                margin: 0;
            }
        }

        .footer-item.email-item {
            width: 100%;
            padding: 1.25rem 0 1.875rem;

            .sign-up-title {
                float: left;
            }
        }

        .footer-item.social-item {
            width: 14.5625rem;
            padding: 0;
            margin-bottom: 1.875rem;

            h4 {
                font-family: $font-family_1;
                font-size: 17px;
                font-size: 1.0625rem;
                line-height: 24px;
                line-height: 1.5rem;
            }
        }

        .menu-footer {
            display: block !important;

            li {
                padding: 0;
                margin-bottom: 0.5rem;
            }
        }
    }

    .social-links {
        margin-top: 0;

        li {
            padding: 0 0.75rem;
        }

        li.last {
            text-align: center;
            padding: 0 0.75rem;
        }
    }

    #email-alert-signup {
        float: right;
        max-width: 23.75rem;
    }

    .copyright {
        line-height: 30px;
        line-height: 1.875rem;

        .copyright-container {
            box-sizing: border-box;
            margin: auto;
            width: calc(100% - 30px);
            max-width: 1280px;

            .copy-links {
                float: left;

                a {
                    margin: 0 0.9375rem;
                }

                a.first {
                    margin-left: 0;
                }
            }

            .copy {
                float: right;
            }
        }
    }

    .ui-dialog {
        .ui-dialog-buttonpane {
            .ui-dialog-buttonset {
                .button.primary {
                    width: 150px;
                    padding-left: 0;
                    padding-right: 0;
                }

                .button.secondary {
                    width: 150px;
                    padding-left: 0;
                    padding-right: 0;
                }

                .button.tertiary {
                    width: 150px;
                    padding-left: 0;
                    padding-right: 0;
                }

                .button.large {
                    span {
                        font-size: 21px;
                        font-size: 1.3125rem;
                    }
                }
            }
        }

        form[name$="profile_address"] {
            .form-row-button {
                button {
                    width: 48%;
                    padding-left: 0;
                    padding-right: 0;
                    margin: 0 1%;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .form-row-button.with-delete {
                button {
                    width: 32%;
                    padding-left: 0;
                    padding-right: 0;
                    margin: 0 0.5%;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .slot-grid-header {
        .search-header-banner {
            margin-top: -2.25rem;
        }
    }

    .search-bottom-banner {
        margin: 4.125rem 0 1.875rem;

        .search-promo-item {
            float: left;
            width: 50%;
            margin: 0;
        }

        .search-promo-item.left {
            padding-right: 2%;
        }

        .search-promo-item.right {
            padding-left: 2%;
        }
    }

    .horizontal-carousel-with-arrows {
        .slick-slide {
            width: 19%;
        }
    }

    .horizontal-carousel {
        .slick-slide {
            width: 19%;
        }
    }

    .horizontalcarousel-with-banner {
        margin-top: 3.75rem;

        .carousel-wrapper {
            padding: 0;

            .horizontal-carousel-with-arrows {
                padding: 0 1.875rem;

                .slick-prev {
                    left: -30px;
                }

                .slick-next {
                    right: -30px;
                }
            }
        }
    }

    .menu-slot {
        width: 13.5rem;
        display: block;
    }

    .category-slot {
        .category-tile {
            width: 33%;
        }

        .search-result-items {
            .grid-tile {
                width: 33%;
                box-sizing: border-box;
            }
        }
    }

    .product-slot {
        .category-tile {
            width: 33%;
        }

        .search-result-items {
            .grid-tile {
                width: 33%;
                box-sizing: border-box;
            }
        }
    }

    #ModalViewDialog {
        .form-row.radio {
            max-height: 26.5625rem;
        }
    }

    .col-items {
        .enter_partial {
            float: left;
            width: 48%;

            + {
                .right_details {
                    float: right;
                    width: 48%;
                    margin-top: 0;
                }
            }
        }
    }

    .pt_product-details {
        #main {
            width: calc(100% - 2.5rem);
        }

        .breadcrumb {
            display: block;
            padding: 0;
            max-width: 1220px;
            margin: 0 auto 1.25rem;
        }
    }

    .product-options {
        .attribute {
            margin: 1.875rem 0 1.25rem;
        }

        .attribute.pnc-inverse {
            margin-bottom: 0;

            .label {
                margin-bottom: 1.25rem;
            }

            .swatches {
                li.inverse {
                    margin-bottom: 0.25rem;
                }
            }
        }
    }

    .product-variations {
        .attribute {
            margin: 1.875rem 0 1.25rem;
        }

        .attribute.pnc-inverse {
            margin-bottom: 0;

            .label {
                margin-bottom: 1.25rem;
            }

            .swatches {
                li.inverse {
                    margin-bottom: 0.25rem;
                }
            }
        }
    }

    .product-main-attributes {
        .attribute {
            margin-bottom: 1.25rem;
        }

        .label {
            display: inline-block;
            min-width: 7.5rem;
        }
    }

    .pdp-callout-message {
        .content-asset {
            margin-top: 2.5rem;
            padding-left: 4.0625rem;
        }
    }

    .pdp-main {
        .product-col-2 {
            float: left;
            width: 55.738%;
        }

        .product-col-1 {
            float: right;
            width: 39.345%;
            clear: right;
        }

        .product-col-3 {
            float: right;
            width: 39.345%;
            clear: right;
        }

        .product-col-3.product-set {
            width: 100%;
            float: none;
            clear: both;
        }

        .product-name {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        #BVRRSummaryContainer {
            margin-bottom: 1.25rem;
        }

        .product-price {
            font-size: 25px;
            font-size: 1.5625rem;
        }

        .installments-product-callout {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        .monthly-product-price {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        .product-desc {
            margin: 1.1875rem 0 1.0625rem;
        }

        .pnc {
            .pnc-product {
                .pnc-product-info {
                    .pnc-name {
                        font-size: 21px;
                        font-size: 1.3125rem;
                    }
                }
            }
        }

        .personalization-title {
            padding-top: 1.5625rem;
        }

        .esp-box {
            padding: 0.625rem;

            .esp-content {
                padding: 0.625rem;
                margin: 0.625rem;
            }
        }

        .personalization-options {
            .personalization-option {
                padding: 0.875rem 1.25rem;
            }
        }

        .ps-personalization-options {
            .personalization-option {
                padding: 0.875rem 1.25rem;
            }
        }

        .product-add-to-cart {
            padding: 1.875rem 0;
            margin-bottom: 1.25rem;
        }

        .recommendations-container {
            + {
                .tab {
                    border-top: 0;
                }
            }
        }

        .recommendations {
            padding: 1.875rem 0 1.5625rem;

            h3 {
                margin-bottom: 1.5625rem;
            }
        }

        .promotion {
            .promotion-callout {
                font-size: 21px;
                font-size: 1.3125rem;
            }
        }
    }

    .product-set {
        .product-add-to-cart.anchor-wrap {
            padding-bottom: 1.25rem;
        }

        .product-add-to-cart.product-add-all-to-cart {
            margin: 1.875rem 0;
            line-height: 50px;
            line-height: 3.125rem;
            height: 3.125rem;
            display: none;

            .add-all-to-cart-message {
                padding-right: 1.25rem;
                text-align: right;
                margin: 0;
                width: calc(100% - 20rem);
            }

            button {
                max-width: 20rem;
            }
        }
    }

    .product-set-list {
        .product-set-item {
            padding: 1.25rem 0 3.75rem;
        }

        .product-set-image {
            float: left;
            width: 17%;
            max-width: 12.5rem;
            min-height: 12.5rem;
            margin: 0;
        }

        .product-set-details {
            float: left;
            width: 45%;
            max-width: 37.5rem;
            padding-left: 1.875rem;
            box-sizing: border-box;
        }

        .product-set-header {
            float: left;
            width: 45%;
            max-width: 37.5rem;
            padding-left: 1.875rem;
            box-sizing: border-box;
        }

        .product-add-to-cart-info {
            float: right;
            width: 37%;
            max-width: 27.5rem;
            clear: right;
        }

        .product-pricing-info {
            float: right;
            width: 37%;
            max-width: 27.5rem;
            clear: right;
        }

        .product-name {
            margin-bottom: 0;
        }

        .rating-wrapper {
            margin-bottom: 1.25rem;
        }

        .pdp-callout-message {
            .content-asset {
                margin-top: 3.4375rem;
            }
        }
    }

    .tabs {
        margin-top: 1.25rem;

        .tab {
            padding-bottom: 1.875rem;

            .tab-header {
                &::after {
                    content: none;
                }
            }

            .tab-info {
                float: left;
                width: 55%;

                + {
                    .tab-info {
                        margin-top: 2.5rem;
                    }

                    .tab-additional-info {
                        display: inline-block;
                        width: 39%;
                        margin-top: 0;
                        margin-left: 6%;
                    }
                }
            }
        }
    }

    .cross-sell {
        ul {
            li.grid-tile {
                margin-left: 0.4375rem;
            }
        }
    }

    button#product-set-add-to-cart {
        width: 130px;
    }

    #product-content {
        .rating-wrapper {
            margin-bottom: 1.25rem;
        }
    }

    .store-tile {
        width: 31%;
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        margin: 0.3rem 1%;
    }

    .cart-promo {
        .label {
            font-family: $font-family_1;
            font-weight: 900;
            font-size: 20px;
            font-size: 1.25rem;
            display: inline-block;
        }

        .value {
            font-family: $font-family_2;
            font-weight: 400;
            font-size: 20px;
            font-size: 1.25rem;
            display: inline-block;
        }
    }

    .cart-coupon-code {
        input {
            width: 45%;
        }
    }

    .pt_cart {
        .cart-actions {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            >button {
                display: inline-block;
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                width: 300px;
                padding: 0;
            }

            .cart-action-continue-shopping {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                margin-top: 0;
            }
        }

        .cart-coupon-code {
            input {
                margin-right: 3%;
            }
        }

        .cart-order-totals {
            .order-total {
                font-size: 21px;
                font-size: 1.3125rem;
            }
        }

        .cart-callout-message {
            border-bottom: 1px solid #ababab;
            padding-bottom: 1.875rem;

            .label {
                font-family: $font-family_1;
                font-weight: 900;
                font-size: 20px;
                font-size: 1.25rem;
                display: inline-block;
            }

            .value {
                font-family: $font-family_2;
                font-weight: 400;
                font-size: 20px;
                font-size: 1.25rem;
                display: inline-block;
            }
        }
    }

    .checkoutmultishipping {
        margin-top: 1.875rem;

        .section-header {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        .item-list-heading {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;

            .section-header {
                font-size: 21px;
                font-size: 1.3125rem;
            }

            .item-shipping-address {
                padding-left: 1.875rem;
                padding-top: 0.125rem;

                div {
                    display: inline-block;
                }

                a {
                    float: right;
                    padding-top: 0;
                }
            }
        }

        .ship-to-multiple {
            border-top: 1px solid #ababab;
        }

        .cart-row.shipments {
            .cart-row-grouping {
                .item-details {
                    width: 45%;
                    padding-right: 0.625rem;
                }

                .quantitycolumn {
                    width: 55%;

                    .product-availability-list {
                        width: 50%;
                    }
                }
            }
        }

        .cart-row-grouping {
            width: 70%;
        }

        .cart-row-inner-details {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
        }

        .shipping-method.multiship {
            .select-style {
                width: 50%;
            }
        }

        .shipping-delay.multiship {
            .select-style {
                margin-bottom: 0;
            }
        }
    }

    .checkoutplaceorder {
        margin-top: 1.875rem;
        margin-top: 3.75rem;

        .section-header {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        .item-list-heading {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;

            .section-header {
                font-size: 21px;
                font-size: 1.3125rem;
            }

            .item-shipping-address {
                padding-left: 1.875rem;
                padding-top: 0.125rem;

                div {
                    display: inline-block;
                }

                a {
                    float: right;
                    padding-top: 0;
                }
            }
        }

        .ship-to-multiple {
            border-top: 1px solid #ababab;
        }

        .cart-row.shipments {
            .cart-row-grouping {
                .item-details {
                    width: 45%;
                    padding-right: 0.625rem;
                }

                .quantitycolumn {
                    width: 55%;

                    .product-availability-list {
                        width: 50%;
                    }
                }
            }
        }

        .cart-row-grouping {
            width: 70%;
        }

        .cart-row-inner-details {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
        }

        .shipping-method.multiship {
            .select-style {
                width: 50%;
            }
        }

        .shipping-delay.multiship {
            .select-style {
                margin-bottom: 0;
            }
        }

        .cart-row {
            .item-quantity {
                width: 30%;
                margin-top: 0;
            }

            .mobile-only {
                display: none;
            }
        }

        .item-total {
            width: 25%;
            text-align: right;
            margin-top: 0;
        }
    }

    .orderdetails-checkout {
        margin-top: 1.875rem;
        margin-top: 3.75rem;
        margin-top: 0.625rem;

        .section-header {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        .item-list-heading {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;

            .section-header {
                font-size: 21px;
                font-size: 1.3125rem;
            }

            .item-shipping-address {
                padding-left: 1.875rem;
                padding-top: 0.125rem;

                div {
                    display: inline-block;
                }

                a {
                    float: right;
                    padding-top: 0;
                }
            }
        }

        .ship-to-multiple {
            border-top: 1px solid #ababab;
        }

        .cart-row.shipments {
            .cart-row-grouping {
                .item-details {
                    width: 45%;
                    padding-right: 0.625rem;
                }

                .quantitycolumn {
                    width: 55%;

                    .product-availability-list {
                        width: 50%;
                    }
                }
            }
        }

        .cart-row-grouping {
            width: 70%;
        }

        .cart-row-inner-details {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
        }

        .shipping-method.multiship {
            .select-style {
                width: 50%;
            }
        }

        .shipping-delay.multiship {
            .select-style {
                margin-bottom: 0;
            }
        }

        .cart-row {
            .item-quantity {
                width: 30%;
                margin-top: 0;
            }

            .mobile-only {
                display: none;
            }
        }

        .item-total {
            width: 25%;
            text-align: right;
            margin-top: 0;
        }

        .order-date {
            float: right;
        }
    }

    .pt_checkout {
        #main {
            padding-top: 0.9375rem;
        }

        .universal-drawer {
            .drawer-handle {
                border-top: 1px solid #ababab;
            }
        }
    }

    .checkout-progress-indicator {
        font-family: $font-family_1;
        font-size: 19px;
        font-size: 1.1875rem;
        padding: 1.25rem 0 3.75rem;
        border-bottom: 0;

        li {
            padding-top: 0;
            display: -webkit-box;
            display: flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            .icon {
                font-size: 18px;
                font-size: 1.125rem;
                position: relative;
                top: auto;
                left: auto;
                transform: none;
                height: 24px;
                width: 24px;
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                margin-right: 0.625rem;
            }

            .progress-step-number {
                font-size: 18px;
                font-size: 1.125rem;
                position: relative;
                top: auto;
                left: auto;
                transform: none;
                height: 24px;
                width: 24px;
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                margin-right: 0.625rem;
            }

            >span {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }

            >a {
                display: -webkit-box;
                display: flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                >span {
                    -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 2;
                    order: 2;
                }
            }
        }
    }

    .universal-drawer {
        .drawer-handle {
            padding: 1.25rem 0.875rem 1.25rem 0;
        }

        .drawer-contents {
            padding: 0 1.25rem 1.25rem;
        }
    }

    .drawer-tile-layout {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
    }

    .drawer-tile-layout.wrap-reverse {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        flex-flow: row-reverse wrap;
    }

    .input-with-tooltip {
        .field-wrapper {
            width: 50%;
        }

        .tooltip {
            left: 51%;
            right: auto;
        }
    }

    .address-modal {
        .ui-dialog-titlebar {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        .actions {
            button {
                width: 48%;
            }

            .cancel {
                margin-top: 0;
                margin-left: 2%;
            }
        }
    }

    .summary {
        .need-help-asset {
            span {
                &:last-child {
                    font-size: 21px;
                    font-size: 1.3125rem;
                }
            }
        }
    }

    .ship-to-multiple {
        a {
            float: right;
            margin-top: 0;
        }

        button {
            float: right;
            margin-top: 0;
        }
    }

    .checkout-shipping {
        .section-header {
            font-size: 21px;
            font-size: 1.3125rem;
        }
    }

    .shipping-methods {
        legend {
            font-size: 21px;
            font-size: 1.3125rem;
        }
    }

    .section-header {
        .shipping-chart {
            float: right;
            margin-top: 0;
        }
    }

    .billing-coupon-code {
        .form-row {
            width: 50%;
        }

        .giftcert-apply {
            width: 50%;
        }
    }

    .select-payment-methods {
        .universal-drawer-cabinet {
            .universal-drawer {
                .drawer-handle {
                    font-family: $font-family_1;
                    font-size: 17px;
                    font-size: 1.0625rem;

                    div {
                        width: auto;
                    }
                }
            }
        }

        .inline-icon {
            .icon {
                width: 4.0625rem;
                height: 2.625rem;
                margin-right: 0.9375rem;
                margin-left: 0.625rem;
                float: none;
            }
        }
    }

    .credit-questions-asset {
        .question-links {
            div {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;

                a {
                    margin-right: 2%;
                    margin-bottom: 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .rates-terms-dialog {
        max-width: 70% !important;
    }

    .payment-method {
        .payment-method-dob {
            display: -webkit-box;
            display: flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            .birthdate {
                .field-wrapper {
                    width: 50%;
                }
            }
        }

        .expiration-date {
            display: -webkit-box;
            display: flexbox;
            display: flex;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;

            .month.form-row {
                margin: 0 5px 0 0;
            }

            .month {
                >label {
                    width: 150%;
                }
            }
        }

        .accepted-cards-block {
            width: 40%;

            .icon {
                width: 19%;
                margin-right: 2%;
                margin-bottom: 1.25rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .order-receipt-summary {
        display: -webkit-box;
        display: flexbox;
        display: flex;

        .order-component-block {
            margin-bottom: 3.125rem;
            padding-bottom: 0;
            display: inline-block;
            width: 28.3333%;

            &:first-child {
                margin-right: 5%;
                border-bottom: 0;
                padding-bottom: 0;
            }

            &:last-child {
                margin-left: 5%;
            }
        }

        .section-header {
            border-bottom: 1px solid #ababab;
            margin-bottom: 1.25rem;
        }
    }

    .place-order-billing-info {
        display: -webkit-box;
        display: flexbox;
        display: flex;

        .order-component-block {
            margin-bottom: 3.125rem;
            padding-bottom: 0;
            display: inline-block;
            width: 45%;

            &:first-child {
                margin-right: 5%;
                border-bottom: 0;
                padding-bottom: 0;
            }

            &:last-child {
                margin-left: 5%;
            }
        }

        .section-header {
            border-bottom: 1px solid #ababab;
            margin-bottom: 1.25rem;
        }
    }

    .out-of-wallet-section {
        width: 50%;
    }

    .out-of-wallet-questions {
        .credit-check-question {
            .form-row-button {
                width: 50%;
            }

            p {
                width: 50%;
            }
        }
    }

    .downpayment {
        .order-info-block {
            .label {
                width: 25%;
            }
        }

        .dp-wrapper {
            padding: 1.25rem;
        }
    }

    #inline-billing-form {
        #EditAddressForm {
            .editaddress {
                .form-row {
                    label.error {
                        padding-left: 25px;
                        position: relative;

                        .error-icon {
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }
                    }

                    &:nth-of-type(7) {
                        width: 48%;
                        margin-right: 2%;
                        display: inline-block;
                        vertical-align: top;
                        float: left;
                    }

                    &:nth-of-type(8) {
                        width: 48%;
                        margin-left: 2%;
                        display: inline-block;
                        vertical-align: top;
                    }

                    &:nth-of-type(9) {
                        width: 100%;
                        display: inline-block;
                        vertical-align: top;
                    }
                }

                .formfield {
                    label.error {
                        padding-left: 25px;
                        position: relative;

                        .error-icon {
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }
                    }

                    &:nth-of-type(7) {
                        width: 48%;
                        margin-right: 2%;
                        display: inline-block;
                        vertical-align: top;
                        float: left;
                    }

                    &:nth-of-type(8) {
                        width: 48%;
                        margin-left: 2%;
                        display: inline-block;
                        vertical-align: top;
                    }

                    &:nth-of-type(9) {
                        width: 100%;
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            }
        }
    }

    .inline-shipping-form {
        #EditAddressForm {
            .editaddress {
                .form-row {
                    label.error {
                        padding-left: 25px;
                        position: relative;

                        .error-icon {
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }
                    }

                    &:nth-of-type(7) {
                        width: 48%;
                        margin-right: 2%;
                        display: inline-block;
                        vertical-align: top;
                        float: left;
                    }

                    &:nth-of-type(8) {
                        width: 48%;
                        margin-left: 2%;
                        display: inline-block;
                        vertical-align: top;
                    }

                    &:nth-of-type(9) {
                        width: 100%;
                        display: inline-block;
                        vertical-align: top;
                    }
                }

                .formfield {
                    label.error {
                        padding-left: 25px;
                        position: relative;

                        .error-icon {
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }
                    }

                    &:nth-of-type(7) {
                        width: 48%;
                        margin-right: 2%;
                        display: inline-block;
                        vertical-align: top;
                        float: left;
                    }

                    &:nth-of-type(8) {
                        width: 48%;
                        margin-left: 2%;
                        display: inline-block;
                        vertical-align: top;
                    }

                    &:nth-of-type(9) {
                        width: 100%;
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            }
        }
    }

    #shipment-arrive-by {
        label {
            white-space: nowrap;
        }
    }

    .shipment-arrive-by {
        label {
            white-space: nowrap;
        }
    }

    .registry-search {
        .form-row {
            width: 33%;

            .field-wrapper {
                width: 90%;
            }
        }
    }

    .wishlist-search {
        .form-row {
            width: 33%;

            .field-wrapper {
                width: 90%;
            }
        }
    }

    .page-content-tab-navigaton {
        display: block;

        button {
            margin-right: 3.125rem;
        }
    }

    .gift-certificate-purchase {
        .content-asset {
            float: left;
            box-sizing: border-box;
            width: 50%;
            padding-right: 1rem;
        }

        form {
            float: left;
            width: 50%;
        }
    }

    .checkoutlogin {
        hr {
            width: 300px;
            margin-left: 0;
            margin-bottom: 1rem;
        }

        .col-1 {
            form {
                width: 65%;
            }

            .login-box {
                padding-right: 120px;
            }
        }

        h1 {
            font-size: 31px;
            font-size: 1.9375rem;
        }

        .form-row-button {
            button {
                width: 17.75rem;
                padding: 0;
            }
        }

        button[name$="login_unregistered"] {
            width: 18.75rem;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_register"] {
            width: 300px;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_login"] {
            width: 15rem;
            padding-left: 0;
            padding-right: 0;
        }

        .login-rememberme {
            margin: 0 0 0 1em;
        }
    }

    .container-login-account {
        h1 {
            font-size: 31px;
            font-size: 1.9375rem;
        }

        .form-row-button {
            button {
                width: 17.75rem;
                padding: 0;
            }
        }

        button[name$="login_unregistered"] {
            width: 18.75rem;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_register"] {
            width: 300px;
            padding-left: 0;
            padding-right: 0;
        }

        button[name$="login_login"] {
            width: 15rem;
            padding-left: 0;
            padding-right: 0;
        }

        .login-rememberme {
            margin: 0 0 0 1em;
        }
    }

    .pt_account {
        #main.registered {
            .account-nav-registered {
                margin: 0 0 1.5625rem;
            }
        }
    }

    .pt_order {
        #main.registered {
            .account-nav-registered {
                margin: 0 0 1.5625rem;
            }
        }
    }

    .account-nav-unregistered {
        border-top: 1px solid #ababab;
        margin: 1.25rem 0;
    }

    #main.registered {
        .account-module.manage-credit-card {
            h4 {
                font-size: 21px;
                font-size: 1.3125rem;
            }

            .button {
                width: 260px;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .account-module.ag-account-registration {
            .section-header {
                font-size: 21px;
                font-size: 1.3125rem;
            }
        }
    }

    #main:not(.registered) {
        #accountGuardPage {
            .account-module.manage-credit-card {
                h4 {
                    font-size: 21px;
                    font-size: 1.3125rem;
                }

                .button {
                    width: 260px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .account-module.ag-account-registration {
                .section-header {
                    font-size: 21px;
                    font-size: 1.3125rem;
                }
            }
        }
    }

    .account-menu-select {
        display: none;
    }

    .account-menu {
        display: block !important;
    }

    #PasswordResetForm {
        button {
            width: 340px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .account-registration {
        h2 {
            font-size: 21px;
            font-size: 1.3125rem;
        }

        form {
            float: left;
            width: 70%;
        }

        .form-row.postal {
            float: left;
            width: 48%;
            margin-left: 4%;
        }

        .form-row.states {
            float: left;
            width: 48%;
        }

        .form-row-button {
            button {
                width: inherit;
            }
        }

        .account-registration-right {
            float: right;
            width: 25%;
        }
    }

    .address-list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;

        li {
            min-height: 135px;
            margin: 0 1.25rem;
            width: 30%;
            border: 0;

            &:first-child {
                margin-left: 0;
            }

            &:nth-child(3n+3) {
                margin-right: 0;
            }

            &:nth-child(3n+1) {
                margin-left: 0;
            }
        }
    }

    .button.address-create {
        width: 21.25rem;
        padding-left: 0;
        padding-right: 0;
    }

    .payment-list {
        li {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -ms-flex-line-pack: justify;
            align-content: space-between;

            .cc-expires {
                margin-bottom: 0;

                .cc-number {
                    margin-right: 3.125rem;
                }
            }

            .cc-type-number {
                margin-bottom: 0;

                .cc-number {
                    margin-right: 3.125rem;
                }
            }

            form[name="payment-remove"] {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;
                -ms-flex-line-pack: justify;
                align-content: space-between;
                width: 15%;

                .form-row.month {
                    margin: 0 1%;
                }

                .form-row.year {
                    margin: 0 1%;
                }

                button[name*="paymentinstruments_creditcards_storedcards"] {
                    margin: 0 1%;
                    margin-left: 1.5625rem;
                }
            }

            form[name="payment-update"] {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;
                -ms-flex-line-pack: justify;
                align-content: space-between;
                width: 60%;

                .form-row.month {
                    margin: 0 1%;
                }

                .form-row.year {
                    margin: 0 1%;
                }

                button[name*="paymentinstruments_creditcards_storedcards"] {
                    margin: 0 1%;
                    margin-left: 1.5625rem;
                }
            }
        }
    }

    #CreditCardForm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        .form-row {
            margin: 0.625rem 2% 0;
        }

        .form-row.cc-type {
            margin-left: 0;
            max-width: 20%;
        }

        .form-row.year {
            margin-right: 0;
        }

        .form-row.creditcard-number {
            min-width: 25%;
        }

        .form-row-button {
            button {
                width: 21.25rem;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .facebook-registration {
        left: 0;
        width: 100% !important;

        .fb_iframe_widget {
            width: 100% !important;

            span {
                width: 100% !important;

                iframe {
                    width: 100% !important;
                }
            }
        }
    }

    .manage-password {
        button[name$="resetpassword_send"] {
            width: 18.75rem;
            padding-left: 0;
            padding-right: 0;
            margin-right: 1.875rem;
        }
    }

    .account-module.billing-address {
        .button {
            width: 18.75rem;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .account-module-unlinked.full-width {
        .col-1 {
            .account-welcome {
                margin-bottom: 0;

                .welcome-user {
                    font-size: 31px;
                    font-size: 1.9375rem;
                    margin-top: 0;
                }
            }
        }
    }

    .account-summary-linked {
        .account-name {
            font-size: 31px;
            font-size: 1.9375rem;
        }

        .account-summary-items {
            >div.account-plan-available-credit {
                .value {
                    font-size: 31px;
                    font-size: 1.9375rem;
                }
            }
        }
    }

    .list-items {
        .list-item {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .item.item-actions {
                line-height: 44px;
                line-height: 2.75rem;
                text-align: right;
            }
        }
    }

    .list-items.row-4 {
        .item {
            width: 25%;
        }
    }

    .list-items.row-5 {
        .item {
            width: 20%;
        }
    }

    .list-items.row-6 {
        .item {
            width: 16.6667%;
        }
    }

    .autopayment-wrapper {
        .payment-amount {
            margin-bottom: 2.5rem;
        }

        .payment-methods {
            .expiration-date {
                .month {
                    >label {
                        width: 150%;
                    }
                }
            }
        }

        .pending-payment-message {
            margin-bottom: 1.5625rem;
        }

        .form-row-button {
            margin-top: 2.5rem;

            button {
                width: auto;
            }
        }

        .cvn {
            width: 50%;

            .field-wrapper {
                width: 100%;
            }
        }
    }

    .make-payment {
        .payment-amount {
            margin-bottom: 2.5rem;
        }

        .payment-methods {
            .expiration-date {
                .month {
                    >label {
                        width: 150%;
                    }
                }
            }
        }

        .pending-payment-message {
            margin-bottom: 1.5625rem;
        }

        .form-row-button {
            margin-top: 2.5rem;
        }
    }

    .createbenefits {
        h3 {
            font-size: 21px;
            font-size: 1.3125rem;
        }
    }

    .return-and-pagination {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .how-to-return {
            border-bottom: 0;
            margin: 0;
            padding: 0;
            text-align: left;
        }

        .search-result-options {
            margin-left: auto;
        }

        .pagination {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;

            .results-hits {
                margin: 0 2.5rem 0 0;
            }
        }
    }

    .order-history-item-summary {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        padding: 0.625rem 0;

        .order-amount {
            width: 20%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
        }

        .order-date {
            width: 20%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
        }

        .order-number {
            width: 20%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
        }

        .order-detail-toggle {
            -ms-flex-item-align: center;
            align-self: center;
            margin-left: auto;
            margin-right: 1.25rem;
            border: 0;
            padding: 0;
            text-align: left;
        }
    }

    .order-details {
        .shipping-address {
            .ship-to {
                font-size: 25px;
                font-size: 1.5625rem;
            }
        }
    }

    .grid-tile {
        .product-tile {
            padding: 1.25rem;
        }
    }

    .product-tile {
        .product-compare {
            display: block;
        }
    }

    .pt_content-search-result {
        #main {
            padding: 1.5625rem 1.25rem;
        }

        .breadcrumb {
            display: block;
        }
    }

    .pt_product-search-noresult {
        #main {
            padding: 1.5625rem 1.25rem;
        }

        .breadcrumb {
            display: block;
        }
    }

    .pt_product-search-result {
        #main {
            padding: 1.5625rem 1.25rem;
        }

        .breadcrumb {
            display: block;
        }
    }

    .no-hits-middle {
        padding-bottom: 0.3125rem;

        .no-hits-help {
            width: 49%;
            box-sizing: border-box;
            float: left;
        }

        .no-hits-search {
            width: 49%;
            box-sizing: border-box;
            float: left;
            float: right;
        }
    }

    .search-result-options.top-options {
        margin-bottom: 1.25rem;

        .pagination {
            width: calc(100% - 20.875rem);

            ul {
                + {
                    .results-hits {
                        margin: 0;
                    }
                }
            }
        }
    }

    .search-result-options.bottom-options {
        border-top: 1px solid #ababab;
    }

    .refinement-select {
        max-width: 8.75rem;
        margin-right: 1.25rem;
    }

    .sort-by {
        max-width: 10.875rem;
    }

    .pagination {
        float: right;

        .results-hits {
            text-align: right;
            margin: 0.625rem 0;
        }
    }

    .search-refinements-section {
        .refinement {
            width: 33.333%;
            border-bottom: none;
            border-left: 1px solid #393939;
        }
    }

    .search-result-items {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        .grid-tile {
            width: 33.33%;
            margin: 0 0 1.25rem;
            padding: 0;
            max-width: 33.33%;

            &:nth-child(3n) {
                .product-tile {
                    margin-right: 0;
                }
            }

            .product-tile {
                max-width: 18.75rem;
                margin: 0 auto;
            }
        }

        .grid-tile.new-row {
            .product-tile {
                margin-left: 0;
            }
        }
    }

    .compare-items {
        display: block;
    }

    .pt_categorylanding {
        #secondary {
            display: block;
        }
    }

    .pt_landing {
        .refinements {
            display: block;
        }

        .primary-content {
            padding-left: 2.5rem;
        }
    }

    .primary-callout-text {
        margin-top: 2.5rem;
        padding: 0;
        width: 85%;
    }

    .mobile-refinement-buttons {
        display: none;

        .refinement-header {
            font-size: 25px;
            font-size: 1.5625rem;
        }
    }

    .hero-container {
        .hero-content-inner {
            .type-hero {
                font-size: 72px;
                font-size: 5vw;
                margin-bottom: 1.5rem;
            }

            h3 {
                font-size: 24px;
                font-size: 1.6667vw;
            }

            .button {
                + {
                    .button {
                        margin-left: 4%;
                        margin-top: 0;
                    }
                }
            }

            h1 {
                margin-bottom: 1.5rem;
            }

            h4 {
                margin-bottom: 1.5rem;
            }
        }

        .hero-content-inner.drop-background {
            padding: 1.875rem;
        }
    }

    .hero-container.banner {
        margin-top: 3.125rem;

        .type-hero {
            font-size: 61px;
            font-size: 3.8125rem;
        }

        h2 {
            font-size: 35px;
            font-size: 2.1875rem;
        }
    }

    .hero-container.side-by-side {
        .hero-content {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;

            .call-text {
                width: 50%;
                box-sizing: border-box;
                padding-right: 6%;
                -ms-flex-item-align: center;
                align-self: center;
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            .video {
                width: 50%;
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                margin-bottom: 0;
                -ms-flex-item-align: center;
                align-self: center;
            }

            .video.first {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            .call-text.second {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                padding-right: 0;
                padding-left: 6%;
            }

            .image-capper {
                display: block;
                margin-bottom: 0.625rem;

                img {
                    width: auto;
                }
            }
        }
    }

    .hero-container.secondary-hero {
        margin-top: 3.75rem;
    }

    .content-tiles {
        margin-top: 3.75rem;

        .content-tiles-heading {
            p {
                width: 60%;
                margin: 0 auto;
                padding: 0.625rem 0 0;
            }
        }

        .content-tile-inner-wrapper {
            margin: 30px;
            width: calc(100% - 60px);
            height: calc(100% - 120px);
        }

        .content-tile-container {
            margin-right: 1%;
            margin-left: 1%;
            margin-top: 2%;

            &:first-child {
                margin-left: 0;
            }
        }

        .cta-container {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;

            .button {
                + {
                    .button {
                        margin-left: 4%;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .content-tiles.two-up {
        .content-tile-container {
            width: 49%;

            &:nth-child(2n) {
                margin-right: 0;
            }

            &:nth-child(-n+2) {
                margin-top: 0;
            }
        }
    }

    .content-tiles.three-up {
        .content-tile-container {
            width: 31.9833%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-child(-n+3) {
                margin-top: 0;
            }
        }
    }

    .content-tiles.three-up.long-treatment {
        .content-tile-container {
            margin-bottom: 0;
        }

        .content-tile-inner {
            padding: 0;
        }
    }

    .content-tiles.inner-content {
        .content-tile-inner-wrapper {
            h2 {
                font-size: 31px;
                font-size: 1.9375rem;
                font-size: 45px;
                font-size: 2.8125rem;
            }
        }
    }

    .content-tiles.outer-content {
        h3 {
            font-size: 21px;
            font-size: 1.3125rem;
            font-size: 23px;
            font-size: 1.4375rem;
            margin-bottom: 0;
        }

        h3.link-arrow {
            top: -3px;
        }
    }

    .top-categories-slot {
        .category-tile {
            margin-right: 2%;
            margin-left: 2%;
            margin-top: 4%;
            width: 21%;

            &:nth-child(-n+4) {
                margin-top: 0;
            }
        }
    }

    .promo-banner {
        .item2 {
            font-size: 31px;
            font-size: 1.9375rem;
        }

        .item3 {
            font-size: 31px;
            font-size: 1.9375rem;
        }
    }

    .home-bottom-first {
        .recommendations {
            padding: 1.875rem 0 1.5625rem;
        }
    }

    .home-bottom-second {
        background-position-y: 50px;
    }

    .pt_catalogrequest {
        .catalog-request-bottom-first {
            .col {
                &:first-child {
                    width: 33%;
                    padding: 0;

                    .wrapper-content {
                        padding: 0.625rem 0;
                    }
                }
            }
        }

        #CatalogRequestForm {
            width: 26.25rem;
        }

        .form-row {
            .field-wrapper {
                width: 94%;
            }
        }

        .has-tooltip-max-width {
            .form-field-tooltip {
                width: 5%;
            }
        }

        .container-assets {
            float: left;
            width: 70%;
            padding-top: 1.25rem;
            border-top: 1px solid #ababab;
        }

        .container-form {
            float: left;
            width: 70%;
            padding-top: 1.25rem;
        }

        .catalog-request-right {
            float: right;
            width: 25%;
            display: block;
        }

        .form-row-button {
            button {
                width: 300px;
            }
        }
    }

    .footer-top-promo {
        height: 3.75rem;
        line-height: 60px;
        line-height: 3.75rem;
        text-align: center;
        padding: 0;

        .primary-cta {
            display: inline-block;
            float: none;
            margin-right: 2.5rem;
            font-size: 24px;
            font-size: 1.5rem;
            letter-spacing: -0.1px;
            line-height: inherit;

            span {
                display: inline;
            }
        }

        a {
            float: none;
            margin: 0;
        }
    }

    .header-top-promo {
        height: 1.875rem;
        line-height: 30px;
        line-height: 1.875rem;

        .primary-cta {
            span {
                &:first-of-type {
                    font-size: 25px;
                    font-size: 1.5625rem;
                }
            }
        }
    }

    .add-to-cart-banner {
        a {
            text-align: center;
        }

        .primary-cta {
            display: inline;
            line-height: 50px;
            line-height: 3.125rem;

            span {
                &:first-of-type {
                    font-size: 18px;
                    font-size: 1.125rem;
                }
            }

            span.bold {
                font-size: 20px;
                font-size: 1.25rem;
            }
        }
    }

    .header-bottom-promo {
        height: 1.875rem;
        line-height: 30px;
        line-height: 1.875rem;
    }

    .header-bottom-promo.bottom-asset {
        .primary-cta {
            font-family: $font-family_1;
            font-weight: 900;
            font-size: 18px;
            font-size: 1.125rem;

            span {
                &:last-of-type {
                    &:not(.bold) {
                        font-family: $font-family_2;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

@media (min-width) {
    .h2-alt {
        font-size: 22px;
    }
}

@media (max-width: 1024px) {
    .desktop-only {
        display: none;
    }

    .no-scroll {
        overflow: hidden;
    }

    .button.secondary.ordersearch {
        width: 100%;
    }

    .buttonstyle.secondary.ordersearch {
        width: 100%;
    }

    button.secondary.ordersearch {
        width: 100%;
    }

    input[type="button"].secondary.ordersearch {
        width: 100%;
    }

    .button.secondary.ordersearch.ordersearchguest {
        margin: 10px 10px 10px 0;
    }

    .buttonstyle.secondary.ordersearch.ordersearchguest {
        margin: 10px 10px 10px 0;
    }

    button.secondary.ordersearch.ordersearchguest {
        margin: 10px 10px 10px 0;
    }

    input[type="button"].secondary.ordersearch.ordersearchguest {
        margin: 10px 10px 10px 0;
    }

    .tooltip {
        font-size: 12px;
        font-size: 0.75rem;
    }

    .level-1 {
        >li {
            &:last-child {
                >a {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .mini-cart-content {
        display: none !important;
    }

    .col-1 {
        float: none;
        width: 100%;
    }

    .col-2 {
        float: none;
        width: 100%;
    }

    #cart-table {
        td {
            &:last-of-type {
                padding-bottom: 0.625rem;
            }
        }

        tfoot {
            width: 100vw;
            max-width: 100vw;
            margin-left: calc(-50vw - -50%);
        }
    }

    .account-module {
        .item-list {
            margin-top: 1.25rem;

            td {
                &:last-of-type {
                    padding-bottom: 0.625rem;
                }
            }

            tfoot {
                width: 100vw;
                max-width: 100vw;
                margin-left: calc(-50vw - -50%);
            }
        }

        h4 {
            span {
                display: block;
            }
        }

        .account-module-list-items {
            .list-item.list-item-title {
                display: none;
            }

            .list-item {
                .item {
                    width: 100%;
                }
            }
        }

        .account-module-button-row {
            .button {
                + {
                    .button {
                        margin-top: 1.25rem;
                    }
                }
            }

            form {
                + {
                    .button {
                        margin-top: 1.25rem;
                    }
                }
            }
        }

        .transaction-detail {
            .account-module-list-items {
                .item.first {
                    ~ {
                        .item {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .product-table {
        td {
            &:last-of-type {
                padding-bottom: 0.625rem;
            }
        }
    }

    .pt_gift-registry {
        .item-list {
            td {
                &:last-of-type {
                    padding-bottom: 0.625rem;
                }

                &:not(.item-image) {
                    padding-left: 0.625rem;
                }
            }

            .field-wrapper {
                width: 100%;
                float: left;
            }

            label {
                &:not(.visually-hidden) {
                    width: 100%;
                    float: left;
                }
            }
        }

        #main.registered {
            padding: 0 0.625rem;
        }

        .create-new-registry {
            overflow: hidden;

            p {
                display: none;
            }
        }

        .form-row-button {
            button {
                &:nth-of-type(2) {
                    margin-top: 0.9375rem;
                }
            }
        }
    }

    .pt_wish-list {
        .item-list {
            td {
                &:last-of-type {
                    padding-bottom: 0.625rem;
                }

                &:not(.item-image) {
                    padding-left: 0.625rem;
                }
            }

            .field-wrapper {
                width: 100%;
                float: left;
            }

            label {
                &:not(.visually-hidden) {
                    width: 100%;
                    float: left;
                }
            }
        }

        #main.registered {
            padding: 0 0.625rem;
        }
    }

    .ui-dialog {
        max-width: 98%;
        min-width: 98%;

        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
            padding: 0.625rem;
        }
    }

    .carousel-wrapper-a2c-confirmation {
        padding: 0 35em;
        padding: 0;

        .slick-next {
            top: 45%;
            right: 1.0625rem;
        }

        .slick-prev {
            top: 45%;
            left: 1.0625rem;
        }
    }

    .pt_product-details {
        .primary-content {
            position: relative;
            padding-bottom: 68px;
        }
    }

    #product-nav-container {
        bottom: 0;
        width: 100%;

        div {
            width: 60px;
        }

        div.product-next {
            float: right;
        }

        .divided {
            span {
                border: 0;
            }
        }
    }

    .wistia_thumb {
        .wistia_click_to_play {
            line-height: 1em;
            min-height: 2.5rem;
            font-size: 17px;
            font-size: 1.0625rem;
            display: inline-block;
            transition: all 0.3s ease;
            box-sizing: border-box;
            border-radius: 4px;
            letter-spacing: 0.2px;
            text-align: center;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            font-family: $font-family_1;
            border: 2px solid #eaeaea;
            background-color: $background-color_2;
            color: $color_2;
            max-width: 18.75rem;
            padding: 0.625rem 0;

            &:active {
                text-decoration: none;
                border-color: $border-color_2;
                background-color: $background-color_2;
                color: $color_2;
            }

            &:focus {
                text-decoration: none;
                outline: none;
                border-color: $border-color_2;
                background-color: $background-color_2;
                color: $color_2;
            }

            &:hover {
                text-decoration: none;
                border-color: $border-color_2;
                background-color: $background-color_2;
                color: $color_2;
            }
        }

        .wistia_click_to_play.active {
            text-decoration: none;
            border-color: $border-color_2;
            background-color: $background-color_2;
            color: $color_2;
        }

        .wistia_click_to_play.disabled {
            cursor: default;
            font-family: $font-family_1;
            opacity: 1;
            background: #fff;
            border-color: $border-color_4;
            color: $color_4;

            &:hover {
                font-family: $font-family_1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;
            }

            &:active {
                font-family: $font-family_1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;
            }

            &:focus {
                font-family: $font-family_1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;
            }
        }

        .wistia_click_to_play[disabled] {
            cursor: default;
            font-family: $font-family_1;
            opacity: 1;
            background: #fff;
            border-color: $border-color_4;
            color: $color_4;

            &:hover {
                font-family: $font-family_1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;
            }

            &:active {
                font-family: $font-family_1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;
            }

            &:focus {
                font-family: $font-family_1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;
            }
        }

        .wistia_click_to_play.disabled.active {
            font-family: $font-family_1;
            background: #fff;
            border-color: $border-color_4;
            color: $color_4;
        }

        .wistia_click_to_play[disabled].active {
            font-family: $font-family_1;
            background: #fff;
            border-color: $border-color_4;
            color: $color_4;
        }
    }

    fieldset[disabled] {
        .wistia_thumb {
            .wistia_click_to_play {
                cursor: default;
                font-family: $font-family_1;
                opacity: 1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;

                &:hover {
                    font-family: $font-family_1;
                    background: #fff;
                    border-color: $border-color_4;
                    color: $color_4;
                }

                &:active {
                    font-family: $font-family_1;
                    background: #fff;
                    border-color: $border-color_4;
                    color: $color_4;
                }

                &:focus {
                    font-family: $font-family_1;
                    background: #fff;
                    border-color: $border-color_4;
                    color: $color_4;
                }
            }

            .wistia_click_to_play.active {
                font-family: $font-family_1;
                background: #fff;
                border-color: $border-color_4;
                color: $color_4;
            }
        }
    }

    .pdp-main {
        .product-review-links {
            margin-left: 6%;
        }

        .prSnippetRatingUnclickable {
            float: left;
        }

        .prSnippetTitle {
            float: left;
        }
    }

    .cross-sell {
        ul {
            li.grid-tile {
                margin-left: 0;
                height: 462px !important;
            }
        }
    }

    .sizinginformation {
        padding: 0;

        th {
            font-family: $font-family_2;
            font-weight: 400;
            padding: 0 0 0 2px;
        }

        td {
            padding: 5px;
            text-align: center;
            background: #fff;
        }

        .sizechart {
            padding: 0 !important;
        }
    }

    .js {
        .ui-dialog-content {
            .product-col-2 {
                width: 100%;
            }

            .product-col-1 {
                width: 100%;
            }

            .product-col-3 {
                width: 100%;
            }
        }
    }

    #quickviewbutton {
        display: none !important;
    }

    .pt_cart {
        #secondary {
            display: none;
        }
    }

    .pt_checkout {
        #main {
            width: 100%;
        }

        .primary-focus {
            #primary {
                width: calc(100% - 30px);
                max-width: 1280px;
                margin: auto;
            }
        }
    }

    .universal-drawer-cabinet {
        .universal-drawer.radio-treatment {
            .fake-radio {
                position: absolute;
                left: 0;

                &::before {
                    position: absolute;
                    left: 0;
                }
            }

            .drawer-handle {
                border-top: 1px solid #ababab;

                >span {
                    position: relative;
                    padding-left: 30px;
                    line-height: 1.5em;
                }
            }
        }
    }

    .address-modal {
        position: fixed;
        max-height: 86%;
        overflow-y: scroll;

        .ui-dialog-titlebar {
            position: fixed;
            width: 98%;
            z-index: 2;
        }
    }

    .billing-specific-styles {
        .universal-drawer {
            .drawer-handle {
                border-top: 1px solid #ababab;
            }

            .drawer-contents {
                box-sizing: border-box;
                padding: 0 0.625rem;
            }
        }

        >h2 {
            margin-top: 1.25rem;
            padding-bottom: 0.625rem;
        }
    }

    .select-payment-methods {
        h4 {
            font-family: $font-family_1;
            font-size: 21px;
            font-size: 1.3125rem;
            margin-top: 1.875rem;
        }

        .inline-icon {
            vertical-align: top;
        }

        .inline-icon.new-store-card {
            .add {
                right: 5px;
                top: -2px;
            }
        }
    }

    .rates-terms-dialog {
        font-size: 12px;
        font-size: 0.75rem;
    }

    .payment-method {
        .ssn {
            width: 31.5833%;
            margin-right: 1%;

            &:first-child {
                label {
                    width: 200%;
                }
            }
        }

        .day {
            width: 100%;
        }

        .month {
            width: 100%;
        }

        .year {
            width: 100%;
        }

        .checkout-form-section-header {
            .header-sub {
                display: block;
            }

            >h4 {
                font-family: $font-family_1;
                font-size: 19px;
                font-size: 1.1875rem;
            }
        }

        .extended-agreement {
            label {
                display: block;
                line-height: 1.25rem;
            }
        }

        .cvn {
            width: 50%;

            .error-message {
                width: 200%;
            }
        }
    }

    .payment-method.credit-card {
        .year {
            margin-bottom: 0;
        }
    }

    .checkoutplaceorder {
        .item-list-heading {
            position: relative;

            .item-shipping-address {
                margin-top: 0.625rem;

                div {
                    display: inline-block;
                    line-height: 1.25em;
                }

                a {
                    position: absolute;
                    right: 0;
                    top: 1.25rem;
                }
            }
        }
    }

    .orderdetails-checkout {
        .item-list-heading {
            position: relative;

            .item-shipping-address {
                margin-top: 0.625rem;

                div {
                    display: inline-block;
                    line-height: 1.25em;
                }

                a {
                    position: absolute;
                    right: 0;
                    top: 1.25rem;
                }
            }
        }

        .actions {
            .button {
                width: 100%;
            }
        }
    }

    .secondary-place-order-button {
        width: calc(100% - 30px);
        max-width: 1280px;
        margin: auto auto 1.875rem;
    }

    .order-summary-footer {
        button {
            width: 100%;
        }
    }

    .summary-page {
        #main {
            #secondary {
                .need-help-asset {
                    display: none;
                }
            }
        }

        .summary-content-wrapper {
            #primary {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }

            #secondary {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                margin-bottom: 0;
            }
        }

        .breadcrumb-content {
            width: calc(100% - 30px);
            max-width: 1280px;
            margin: auto;

            .checkout-progress-indicator {
                margin-bottom: 0;
                border-bottom: 0;
            }
        }

        .checkout-mini-cart {
            display: none;
        }
    }

    .downpayment {
        #downPaymentCreditCardList {
            padding-left: 0.9375rem;

            label {
                padding-left: 1.875rem;
                line-height: 1.5em;

                &::before {
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .downpayment-confirmation-container {
        .downpayment-area.first-in-mobile-order {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }

        .downpayment-module.first-in-mobile-order {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }

        .downpayment-area.second-in-mobile-order {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }

        .downpayment-module.second-in-mobile-order {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }
    }

    #EditAddressForm {
        margin-top: 2.5rem;
    }

    #inline-billing-form {
        #EditAddressForm {
            .editaddress {
                width: 100%;
            }
        }
    }

    .inline-shipping-form {
        #EditAddressForm {
            .editaddress {
                width: 100%;
            }
        }
    }

    .list-share {
        .print-page {
            display: none;
        }
    }

    .registry-search {
        .form-row-button {
            button {
                width: 100%;
            }
        }
    }

    .wishlist-search {
        .form-row-button {
            button {
                width: 100%;
            }
        }
    }

    .pt_account {
        #main.registered {
            padding: 0 0.625rem;
        }
    }

    .pt_customer-service {
        #main.registered {
            padding: 0 0.625rem;
        }
    }

    .pt_order {
        #main.registered {
            padding: 0 0.625rem;
        }
    }

    #main.registered {
        .account-module.first-in-mobile-order {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }

        .account-module.second-in-mobile-order {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }
    }

    #main:not(.registered) {
        #accountGuardPage {
            .account-module.first-in-mobile-order {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            .account-module.second-in-mobile-order {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }
        }
    }

    .payment-method-type {
        .credit-card-fields {
            .cvn {
                width: 50%;

                .error-message {
                    width: 200%;
                }
            }
        }
    }

    .order-history-header {
        button {
            font-size: 1em;
            padding: 0.25em;
        }
    }

    .manage-password {
        .success-form {
            margin-top: 1.25rem;
        }
    }

    .autopayment-wrapper {
        .payment-methods {
            .day {
                width: 100%;
            }

            .month {
                width: 100%;
            }

            .year {
                width: 100%;
            }
        }
    }

    .make-payment {
        .payment-methods {
            .day {
                width: 100%;
            }

            .month {
                width: 100%;
            }

            .year {
                width: 100%;
            }
        }
    }

    .modify-payment {
        .form-row-button {
            max-width: 100%;
        }
    }

    .order-history-item-summary {
        .order-amount {
            .value {
                width: 50%;
            }
        }

        .order-date {
            .value {
                width: 50%;
            }
        }

        .order-number {
            .value {
                width: 50%;
            }
        }
    }

    .order-details {
        .order-shipments {
            height: 100%;
        }
    }

    .order-details.svc-result-items.order-status-guest {
        .line-item {
            .line-item-status {
                padding-left: 0;
            }
        }
    }

    .order-history {
        .down-payment {
            width: 100%;
        }
    }

    #wrapper.open-refinements {
        height: 100vh;
        overflow: hidden;

        .menu-overlay {
            display: block;
            background-color: $background-color_4;
            opacity: 0.5;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }
    }

    .pt_content-search-result {
        .primary-content {
            width: 100%;
        }

        #secondary.refinements {
            display: none;
            position: fixed;
            left: 0.625rem;
            top: 0.625rem;
            z-index: 100;
            background: #fff;
            width: calc(100% - 1.25rem);
            height: calc(100% - 20px);
            overflow-y: scroll;
            box-sizing: border-box;
        }
    }

    .pt_product-search-result {
        &:not(.pt_landing) {
            .primary-content {
                width: 100%;
            }

            #secondary.refinements {
                display: none;
                position: fixed;
                left: 0.625rem;
                top: 0.625rem;
                z-index: 100;
                background: #fff;
                width: calc(100% - 1.25rem);
                height: calc(100% - 20px);
                overflow-y: scroll;
                box-sizing: border-box;
            }
        }
    }

    .refinements-content {
        padding: 0 1.25rem 4.375rem;
    }

    .hero-container {
        .hero-content.stacked-banner {
            .hero-content-inner {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;

                .inner-content {
                    &:first-child {
                        -webkit-box-ordinal-group: 3;
                        -ms-flex-order: 2;
                        order: 2;
                        margin: 0 0 1.25rem;
                    }

                    &:last-child {
                        -webkit-box-ordinal-group: 2;
                        -ms-flex-order: 1;
                        order: 1;
                        margin: 0;
                    }
                }
            }
        }

        .hero-content-inner.drop-background {
            background-color: $background-color_5 !important;
        }

        .hero-content-inner {
            &:not(.mobile-content-inside) {
                position: relative;

                .button {
                    width: 100%;
                }
            }

            &:not(.mobile-content-inside)[class*="-center-pos"] {
                top: auto;
                bottom: auto;
                transform: translate(0);
            }

            &:not(.mobile-content-inside)[class*="-left-pos"] {
                top: auto;
                bottom: auto;
                transform: translate(0);
            }

            &:not(.mobile-content-inside)[class*="-right-pos"] {
                top: auto;
                bottom: auto;
                transform: translate(0);
            }

            &:not(.mobile-content-inside)[class*="align-bottom-"] {
                top: auto;
                bottom: auto;
                transform: translate(0);
            }

            &:not(.mobile-content-inside)[class*="align-middle-"] {
                top: auto;
                bottom: auto;
                transform: translate(0);
            }

            &:not(.mobile-content-inside)[class*="align-middle-center"] {
                top: auto;
                bottom: auto;
                transform: translate(0);
            }

            &:not(.mobile-content-inside)[class*="align-top-"] {
                top: auto;
                bottom: auto;
                transform: translate(0);
            }
        }

        .hero-content-inner[class*="mobile-content-inside"] {
            position: absolute;
            padding: 1.25rem 1.25rem 1.875rem;
            box-sizing: border-box;
            width: 100%;
        }

        .hero-content-inner[class*="mobile-content-inside"][class*="-bottom-center"] {
            top: auto;
            right: auto;
            left: 0;
            bottom: 35px;
            text-align: center;
        }

        .hero-content-inner[class*="mobile-content-inside"][class*="-bottom-center"][class*="width-"] {
            max-width: 100%;
        }

        .hero-content-inner[class*="mobile-content-inside"][class*="-middle-center"] {
            top: 0;
            right: auto;
            left: 0;
            bottom: auto;
            text-align: center;
        }

        .hero-content-inner[class*="mobile-content-inside"][class*="-middle-center"][class*="width-"] {
            max-width: 100%;
        }

        .hero-content-inner[class*="mobile-content-inside"][class*="-bottom-left"] {
            top: auto;
            left: 0;
            right: auto;
            bottom: 35px;
            text-align: left;
        }

        .hero-content-inner[class*="mobile-content-inside"][class*="-bottom-left"][class*="width-"] {
            max-width: 100%;
        }

        .hero-content-inner.mobile-center {
            text-align: center;
        }
    }

    .hero-container.side-by-side {
        .hero-content {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }

    .hero-container.third-split {
        .hero-content {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }

    .top-categories-slot {
        .category-tile {
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .add-to-cart-banner {
        .primary-cta {
            display: inline-block;
            width: calc(100% - 99px);
            padding-right: 0.625rem;
            vertical-align: middle;
        }
    }

    .header-bottom-promo.bottom-asset {
        .primary-cta {
            .bold {
                font-family: $font-family_1;
                font-weight: 900;
            }
        }
    }

    .top-banner {
        .header-wrapper {
            svg.icon.account {
                height: 50px;
                width: 50px;
            }

            svg.icon.cart {
                height: 50px;
                width: 50px;
            }

            svg.icon.mobile-menu {
                height: 50px;
                width: 50px;
            }
        }
    }

    svg.icon.signin-mobile-icon {
        height: 28px;
        width: 32px;
    }
}

@media (max-width: 767px) {
    .desktop-tablet {
        display: none;
    }

    .tablet-only {
        display: none;
    }

    .add-to-cart-dialog.ui-dialog {
        .mini-cart-totals {
            .mini-cart-subtotals {
                padding: 0.625rem 0;
                border-top: 1px solid #ababab;
            }
        }
    }

    .footer-container {
        width: calc(100% - 1.25rem);
    }

    .product-actions {
        .socialsharing.set-socialsharing {
            margin: 0 auto 1.875rem;
            float: none;
        }
    }

    .pdp-main {
        .early-bird {
            height: auto;
            display: block;
            padding: 0;
        }

        .recommendations {
            .slick-prev {
                left: 0.75rem;
            }

            .slick-next {
                right: 1rem;
            }
        }
    }

    .tabs {
        .tab {
            .tab-header {
                margin: 1.1875rem 0 0.5625rem;
                cursor: pointer;

                + {
                    .tab-content {
                        display: none;
                    }
                }
            }

            .tab-header.expanded {
                + {
                    .tab-content {
                        display: block;
                    }
                }
            }
        }
    }

    span#product-info-melter_shipping_message {
        padding-top: 7px;
    }

    .js {
        .bonus-product-list {
            .bonus-product-item {
                .product-col-1 {
                    width: 100%;
                    max-width: 100%;
                }

                .product-col-2 {
                    width: 100%;
                    max-width: 100%;
                }

                .product-col-3 {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    #cart-table {
        .cart-row.esp-last-chance {
            td.item-details {
                width: 100%;
                padding-left: 0.25rem;
            }

            img.new-leaf-logo {
                width: 25%;
            }

            .esp-content {
                float: left;
                width: 70%;
            }

            a.openModal {
                margin-left: 0;
                display: block;
            }

            .esp-options {
                margin-top: 1rem;
                margin-left: -35%;
            }

            h5 {
                display: block;
                margin: 1rem 0;
            }
        }
    }

    .account-module {
        .item-list {
            .cart-row.esp-last-chance {
                td.item-details {
                    width: 100%;
                    padding-left: 0.25rem;
                }

                img.new-leaf-logo {
                    width: 25%;
                }

                .esp-content {
                    float: left;
                    width: 70%;
                }

                a.openModal {
                    margin-left: 0;
                    display: block;
                }

                .esp-options {
                    margin-top: 1rem;
                    margin-left: -35%;
                }

                h5 {
                    display: block;
                    margin: 1rem 0;
                }
            }
        }

        .account-module-row {
            .label {
                width: 100%;
                display: block;
            }

            .value {
                width: 100%;
                display: block;

                .order-tracking-value {
                    float: right;
                    text-align: right;
                }
            }
        }
    }

    .cart-coupon-code {
        input {
            float: left;
            width: 44%;
        }

        button {
            float: left;
            margin: 0 0 0 1%;
            padding: 0.5313rem 0;
            width: 22%;
        }

        button.simple {
            width: 32%;
            padding: 0.5313rem 0;
        }
    }

    .pt_checkout {
        .form-row-button {
            button {
                width: 100%;
            }
        }
    }

    .checkout-guest-create-account.review {
        .order-review-account-form {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            padding-bottom: 1rem;
        }
    }

    .orderdetails-checkout {
        .order-summary-section-header {
            margin-bottom: 20px;
        }

        .mini-payment-instrument {
            border-bottom: 1px solid #ababab;
            padding-bottom: 1.875rem;
        }
    }

    .checkoutlogin {
        span.create-later {
            display: block;
            text-align: center;
        }
    }

    .pt_account {
        .primary-content {
            margin-top: 15px;
        }
    }

    .pt_customer-service {
        .primary-content {
            margin-top: 15px;
        }

        #main {
            display: -webkit-box;
            display: flexbox;
            display: flex;
        }

        #secondary {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
        }

        #primary {
            margin-bottom: 0.9375rem;
        }
    }

    .pt_order {
        .primary-content {
            margin-top: 15px;
        }
    }

    .account-menu {
        display: none;
        padding: 15px;
        border: 1px solid #ababab;
        margin: 10px 0;

        .account-nav-asset {
            margin: 30px 0 0;
        }
    }

    .account-module.full-width {
        .order-status {
            .form-row-button {
                margin-top: 25px;
            }
        }
    }

    .list-items {
        .list-item {
            .item {
                .label {
                    width: 50%;
                    float: left;
                }

                .value {
                    width: 50%;
                    float: right;
                }
            }

            .item.item-actions {
                border-top: 1px solid #fff;
                padding-top: 0.625rem;
                text-align: center;
            }
        }
    }

    .autopayment-wrapper {
        .payment-row {
            label {
                .due-on {
                    display: block;
                    padding-left: 1.875rem;
                }
            }
        }

        .payment-method-type {
            .payment-method-type-row {
                .dash {
                    display: none;
                }

                .expires-label {
                    display: block;
                    padding-left: 1.875rem;
                }
            }

            .ach-name-fields {
                .form-row {
                    &:nth-of-type(2) {
                        margin-left: 0;
                    }
                }

                .formfield {
                    &:nth-of-type(2) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .make-payment {
        .payment-row {
            label {
                .due-on {
                    display: block;
                    padding-left: 1.875rem;
                }
            }
        }

        .payment-method-type {
            .payment-method-type-row {
                .dash {
                    display: none;
                }

                .expires-label {
                    display: block;
                    padding-left: 1.875rem;
                }
            }

            .ach-name-fields {
                .form-row {
                    &:nth-of-type(2) {
                        margin-left: 0;
                    }
                }

                .formfield {
                    &:nth-of-type(2) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .order-history-item-summary {
        .order-amount {
            .label {
                width: 50%;
            }
        }

        .order-date {
            .label {
                width: 50%;
            }
        }

        .order-number {
            .label {
                width: 50%;
            }
        }
    }

    .search-result-options.top-options {
        .pagination {
            ul {
                display: none;
            }
        }
    }

    .sort-by {
        float: right;
        width: calc(50% - 0.625rem);
    }

    .home-bottom-first {
        .recommendations-container {
            margin-left: 0.5%;
            margin-right: 1%;
        }

        .recommendations {
            .slick-prev {
                left: 0.19rem;
            }

            .slick-next {
                right: 0.25rem;
            }

            .grid-tile {
                .product-tile {
                    margin-left: 8%;
                }

                .slick-slide {
                    .slick-active {
                        width: 360px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1025px) {
    .tablet-only {
        display: none;
    }

    .tablet-mobile {
        display: none;
    }

    .icon.logo {
        width: 200px;
        height: 72px;
    }

    .slick-dots {
        li {
            button {
                &:focus {
                    outline: none;
                    background-color: $background-color_5;

                    &::before {
                        opacity: 1;
                        background-color: $background-color_6;
                    }
                }

                &:hover {
                    outline: none;
                    background-color: $background-color_5;

                    &::before {
                        opacity: 1;
                        background-color: $background-color_6;
                    }
                }
            }
        }
    }

    .has-tooltip-max-width {
        .field-wrapper {
            width: 94%;
            float: left;
        }

        .form-field-tooltip {
            position: relative;
            top: 0;
            left: 0;
        }
    }

    #wrapper.menu-active {
        left: auto;
    }

    #main {
        padding: 1.5625rem 1.25rem;
    }

    nav[role="navigation"] {
        position: static;
        left: auto;
        width: 100%;
        margin-top: 1px;
    }

    .sub-nav-content-column {
        display: block;
    }

    .account-menu-toggle {
        display: none;
    }

    .menu-toggle {
        display: none;
    }

    .menu-category {
        li {
            border: none;

            &:hover {
                >a {
                    background-color: $background-color_5;
                }
            }
        }

        a {
            font-family: $font-family_1;
            font-weight: 900;
            letter-spacing: 0.3px;
            width: auto;
        }
    }

    .mobile-menu-utility-user {
        li {
            border: none;

            &:hover {
                >a {
                    background-color: $background-color_5;
                }
            }
        }

        a {
            font-family: $font-family_1;
            font-weight: 900;
            letter-spacing: 0.3px;
            width: auto;
        }
    }

    .level-2 {
        .horizontal-banner {
            span {
                font-family: $font-family_1;
                font-weight: 900;
                letter-spacing: 0.3px;
            }
        }
    }

    .menu-columns {
        >ul {
            max-width: 225px;
            min-width: 150px;
        }
    }

    .menu-vertical {
        >li {
            >a {
                font-family: $font-family_1;
                font-weight: 900;
                letter-spacing: 0.3px;
            }
        }
    }

    .menu-utility-user {
        position: absolute;
        top: 3px;
        right: 13%;

        li {
            float: left;
            padding: 0;
            border-color: $border-color_6;
            margin: 0;
            text-align: center;
            background-color: $background-color_5;

            &:first-child {
                border: none;

                svg {
                    position: relative;
                    top: -2px;
                }
            }

            a {
                transition: color 0.5s ease;
                color: $color_2;

                &:hover {
                    text-decoration: none;
                    color: $color_2;
                }
            }
        }

        .user-info {
            position: relative;
            transition: color 0.5s ease;

            .user-panel {
                a {
                    color: $color_2;
                    text-decoration: none;
                    padding: 0;
                    line-height: 2.2;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .user-info.navActive {
            .user-login {
                color: $color_5;
            }

            .user-panel {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .search-suggestion-wrapper {
        width: 150%;
    }

    .search-suggestion-wrapper.full {
        .phrase-suggestions {
            width: 50%;
            float: left;
            padding-left: 1.25rem;
        }

        .product-suggestions {
            width: 50%;
            float: right;
            border-left: 1px solid #757575;
            padding: 0.625rem 0;
        }
    }

    .product-suggestions {
        border-top: none;
        padding-left: 0;
        padding-right: 0;
    }

    .product-suggestion {
        padding: 0.625rem;
    }

    .search-phrase {
        margin-top: 0;
        padding: 0 0.625rem;
    }

    #mini-cart {
        top: 5px;
        right: 7%;
        height: 1.875rem;

        &:hover {
            .mini-cart-content {
                display: block;

                &::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    transform: rotate(45deg);
                    top: -10px;
                    left: 82%;
                    box-shadow: -4px -4px 20px -5px rgb(0 0 0 / 25%);
                    z-index: 100;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 40px;
                    top: -20px;
                    background: transparent;
                }
            }
        }
    }

    .top-banner {
        background: #eaeaea;
        padding: 2.25rem 0 0.625rem !important;

        .header-wrapper {
            margin: 0;
            max-width: 100%;
            -ms-flex-pack: distribute;
            justify-content: space-around;

            .header-search {
                width: 30%;
                margin: 1.25rem 40% 0 !important;
                top: -55px !important;
            }

            #mini-cart {
                top: 15px !important;
                right: 5rem;
            }
        }
    }

    .top-banner.simplified {
        box-shadow: 0 2px 9px 0 #ababab;

        .header-wrapper {
            flex-wrap: nowrap;
        }

        .primary-logo {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
            margin-bottom: 0;
        }

        .simplified-header-content {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            width: auto;
            background-color: $background-color_5;
            margin-right: 5%;
            text-align: right;
            padding: 0;

            .content-asset {
                display: -webkit-box;
                display: flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
            }

            .secure-line {
                margin-right: 12%;

                span {
                    display: inline-block;
                }
            }
        }

        .simplified-mini-cart {
            -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
            order: 3;
            margin-right: 7%;
            position: relative;
            top: auto;
            right: auto;
            margin-bottom: 0.625rem;
        }
    }

    .header-wrapper {
        max-width: 1440px;
        margin: 0 auto;
        position: relative;
    }

    .primary-logo {
        background: url("../images/svg-icons/logo.svg") no-repeat;
        background-size: contain;
        float: left;
        padding: 0;
        margin-left: 5%;
        height: 64px;
        width: 362px;
        top: 0;
    }

    .header-search {
        width: 35%;
        top: -70px;

        input[type="text"] {
            padding: 0 1rem;
            color: $color_3;
            line-height: 2;
        }

        button {
            min-height: 20px;
            padding: 0.2rem 0.6rem;
            line-height: 1.9rem;
        }
    }

    .text-white {
        div {
            color: $color_1;
        }

        h1 {
            color: $color_1;
        }

        h2 {
            color: $color_1;
        }

        h3 {
            color: $color_1;
        }

        span {
            color: $color_1;
        }
    }

    .refinement {
        ul.swatches {
            li {
                margin-right: 0.5rem;
            }
        }
    }

    .footer-container {
        padding: 0.0625rem 0;

        .footer-item {
            padding: 1.5rem 0;
            max-width: 12.5rem;
            width: calc(32.3333% - 11.9583rem);

            &:nth-child(2) {
                max-width: 10rem;
            }

            .note {
                margin: 0.125rem 0 1.0625rem;
            }
        }

        .footer-item.email-item {
            width: 21.9375rem;
            border-right: 1px solid #ababab;
            padding-right: 3.625rem;
            max-width: none;
            margin-right: 3%;
            padding-top: 3.125rem;

            h4 {
                line-height: 24px;
                line-height: 1.5rem;
            }
        }

        .footer-item.social-item {
            width: 13.9375rem;
            padding: 1.5rem 0;
            max-width: none;
            margin-bottom: 0;

            h4 {
                font-size: 18px;
                font-size: 1.125rem;
            }

            .call-us {
                margin-top: 1.0625rem;
            }
        }
    }

    .social-links {
        li.last {
            text-align: right;
            padding-right: 0;
        }
    }

    .ui-dialog {
        max-width: 98%;

        .ui-dialog-titlebar {
            padding: 0.625rem 1.25rem;
        }

        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
            padding: 1.25rem;
        }
    }

    .search-bottom-banner {
        .search-promo-item {
            .search-promo-content {
                top: 1.5rem;
                transform: none;

                h2 {
                    font-size: 44px;
                    font-size: 2.75rem;
                    line-height: 48px;
                    line-height: 3rem;
                    margin: 0 0 1.25rem;
                }
            }
        }
    }

    .content-asset {
        .account-options {
            li {
                width: 27%;
                -ms-flex-preferred-size: 27%;
                flex-basis: 27%;
            }
        }
    }

    .item-dashboard {
        label {
            + {
                .field-wrapper {
                    float: right;
                }
            }
        }

        .option-priority {
            .value {
                line-height: 40px;
                line-height: 2.5rem;
            }

            label {
                line-height: 40px;
                line-height: 2.5rem;
            }
        }

        .option-quantity-desired {
            label {
                line-height: 40px;
                line-height: 2.5rem;
            }
        }

        .option-add-to-cart {
            display: -webkit-box;
            display: flexbox;
            display: flex;

            .inventory {
                width: 8.75rem;
            }

            .button {
                width: calc(100% - 8.75rem);
                margin-top: 0;
            }

            button {
                width: calc(100% - 8.75rem);
                margin-top: 0;
            }
        }
    }

    .product-primary-image.slick-slider {
        .slick-dots {
            height: 0;
            margin: 0;
            overflow: hidden;
        }
    }

    .product-thumbnails {
        display: block;
        float: left;
        width: 67%;
        max-width: 28.125rem;
    }

    .wistia_thumb.pdp-wistia_thumb {
        float: right;
        box-sizing: border-box;
        width: 27%;
        max-width: 11.25rem;
        padding: 0.625rem 3.678%;
        box-shadow: 0 0 9px 0 #ababab;

        .wistia_click_to_play {
            img {
                display: block;
                margin: 0 auto;
            }

            .play-video {
                font-size: 0;
                display: block;
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                padding: 0;
                top: calc(50% - 2.0625rem);
                left: calc(50% - 1.25rem);
            }

            .video-label {
                display: block;
                text-align: center;
                white-space: nowrap;
                font-family: $font-family_1;
                font-weight: 900;
                font-size: 16px;
                font-size: 1rem;
                line-height: 1.25em;
                margin-top: 0.4375rem;
            }
        }
    }

    .pdp-main {
        .recommendations {
            .carousel-recommendations {
                .slick-arrow.slick-prev {
                    left: -1.625rem;
                }

                .slick-arrow.slick-next {
                    right: -1.625rem;
                }
            }
        }
    }

    button#product-set-add-to-cart {
        width: 200px;
    }

    .pt_cart {
        .cart-actions-top {
            display: -webkit-box;
            display: flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            >button {
                display: inline-block;
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                width: 300px;
                padding: 0;
            }

            .cart-action-continue-shopping {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
            }
        }

        .cart-actions.mobile-only {
            display: none;
        }

        .cart-actions {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;

            >button {
                width: 100%;
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            .cart-action-continue-shopping {
                margin-top: 0.3125rem;
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                -webkit-box-flex: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
            }
        }

        .cart-form-wrapper {
            .item-list {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }

            .cart-header-items {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            .cart-bottom {
                -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                order: 3;
            }

            .cart-footer {
                -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                order: 4;
                display: -webkit-box;
                display: flexbox;
                display: flex;

                .cart-footer-inner {
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;

                    &:first-child {
                        margin-right: 5%;
                    }

                    &:last-child {
                        margin-left: 5%;
                    }
                }
            }

            .cart-footer-two {
                -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                order: 5;
            }
        }
    }

    .checkoutmultishipping {
        .cart-row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;

            .item-details {
                width: 75%;
                padding-right: 3.75rem;
            }

            .quantitycolumn {
                width: 25%;
                padding-right: 3.75rem;
                margin-top: 0;
            }

            .shippingaddress {
                width: 35%;
                margin-top: 0;
            }
        }

        .cart-row-inner-details {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            width: 100%;
        }

        #EditAddressForm {
            .field-wrapper {
                width: 100%;
            }
        }
    }

    .checkoutplaceorder {
        .cart-row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;

            .item-details {
                width: 75%;
                padding-right: 3.75rem;
            }

            .quantitycolumn {
                width: 25%;
                padding-right: 3.75rem;
                margin-top: 0;
            }

            .shippingaddress {
                width: 35%;
                margin-top: 0;
            }
        }

        .cart-row-inner-details {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            width: 100%;
        }
    }

    .orderdetails-checkout {
        .cart-row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;

            .item-details {
                width: 75%;
                padding-right: 3.75rem;
            }

            .quantitycolumn {
                width: 25%;
                padding-right: 3.75rem;
                margin-top: 0;
            }

            .shippingaddress {
                width: 35%;
                margin-top: 0;
            }
        }

        .cart-row-inner-details {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            width: 100%;
        }
    }

    .pt_checkout {
        #main {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
        }

        .primary-focus {
            .primary-content {
                box-sizing: border-box;
                padding-right: 3.75rem;
                width: 92%;
                float: none;
            }

            #secondary {
                float: none;
                width: 24%;
                margin-top: 6.875rem;
            }
        }
    }

    .checkout-progress-indicator {
        width: 85%;
    }

    .payment-method {
        .expiration-date {
            display: -webkit-box;
            display: flexbox;
            display: flex;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;

            .month.form-row {
                margin: 0 5px 1.25rem;
            }
        }
    }

    .summary-page {
        .summary-content-wrapper {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
        }

        .breadcrumb-content {
            border-bottom: 1px solid #ababab;

            .checkout-progress-indicator {
                width: 65%;
            }
        }
    }

    .downpayment-confirmation-container {
        box-sizing: border-box;
        margin: auto;
        width: calc(100% - 30px);
        max-width: 1280px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .downpayment-area {
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;
            width: calc(58% - 15px);
        }

        .downpayment-module {
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;
            width: calc(42% - 15px);
            padding: 0 2.5rem;
        }
    }

    .checkoutlogin {
        .login-box {
            input {
                width: 500px;
                margin-right: 10px;
            }

            span.create-later {
                margin-left: 0.5rem;
            }
        }

        .col-1 {
            border-top: 0;
            border-right: 1px solid #ababab;
            padding-top: 0;
            margin-top: 0;

            form {
                width: 100%;
            }

            .form-row-button {
                width: 120%;
            }
        }

        .col-2 {
            border-top: 0;
            padding-top: 0;
            margin-top: 0;

            .login-box {
                padding-left: 80px;
            }
        }
    }

    .container-login-account {
        .col-1 {
            border-top: 0;
            border-right: 1px solid #ababab;
            border: 0;
            padding-top: 0;
            margin-top: 0;
        }

        .col-2 {
            border-top: 0;
            border-left: 1px solid #ababab;
            padding-left: 3%;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .pt_gift-registry {
        .col-1 {
            border-top: 0;
            border-right: 1px solid #ababab;
            padding-top: 0;
            margin-top: 0;
        }

        .col-2 {
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .pt_wish-list {
        .col-1 {
            border-top: 0;
            border-right: 1px solid #ababab;
            padding-top: 0;
            margin-top: 0;
        }

        .col-2 {
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .account-nav-desktop-bottom {
        box-sizing: border-box;
        margin: auto;
        width: calc(100% - 30px);
        max-width: 1280px;

        .back {
            display: none;
        }

        .level-1-header {
            ul {
                list-style: none;
                display: -webkit-box;
                display: flexbox;
                display: flex;
                padding: 0;

                li {
                    padding: 0 3.125rem 0 0;
                    -webkit-box-flex: 0;
                    flex: 0 0 auto;

                    a {
                        font-family: $font-family_1;
                        font-size: 17px;
                        font-size: 1.0625rem;
                        border-bottom: 2px solid transparent;
                        padding-bottom: 0.125rem;

                        &:hover {
                            color: $color_5;
                            border-color: $border-color_7;
                        }
                    }
                }

                li.active {
                    a {
                        color: $color_5;
                        border-color: $border-color_7;

                        &:hover {
                            color: $color_5;
                            border-color: $border-color_7;
                        }
                    }
                }
            }
        }
    }

    #main.registered {
        .account-body {
            box-sizing: border-box;
            margin: auto;
            width: calc(100% - 30px);
            max-width: 1280px;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

        .account-module {
            width: calc(50% - 15px);
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;

            &:not(.no-shadow) {
                padding: 2.5rem;
            }
        }

        .account-module.ag-account-registration {
            padding: 1.5rem 2.5rem 1.5rem 1.5rem;

            .ag-account-form {
                .two-col-per-row {
                    margin-right: -1rem;
                    width: calc(100% + 1rem);

                    .form-row {
                        display: inline-block;
                        vertical-align: top;
                        width: calc(50% - 0.5rem);

                        .field-wrapper {
                            width: 100%;
                        }

                        label {
                            span {
                                white-space: nowrap;
                            }
                        }

                        span.error-icon {
                            margin-top: -0.5rem;
                        }

                        &:first-of-type {
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            .button-fancy-large {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }

        .manage-order {
            .modify-payment {
                &:first-child {
                    -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 2;
                    order: 2;
                }

                &:nth-child(2) {
                    -webkit-box-ordinal-group: 2;
                    -ms-flex-order: 1;
                    order: 1;
                }

                &:last-child {
                    -webkit-box-ordinal-group: 4;
                    -ms-flex-order: 3;
                    order: 3;
                }
            }
        }
    }

    #main:not(.registered) {
        #accountGuardPage {
            .account-body {
                box-sizing: border-box;
                margin: auto;
                width: calc(100% - 30px);
                max-width: 1280px;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
            }

            .account-module {
                width: calc(50% - 15px);
                -webkit-box-ordinal-group: 1;
                -ms-flex-order: 0;
                order: 0;

                &:not(.no-shadow) {
                    padding: 2.5rem;
                }
            }

            .account-module.ag-account-registration {
                padding: 1.5rem 2.5rem 1.5rem 1.5rem;

                .ag-account-form {
                    .two-col-per-row {
                        margin-right: -1rem;
                        width: calc(100% + 1rem);

                        .form-row {
                            display: inline-block;
                            vertical-align: top;
                            width: calc(50% - 0.5rem);

                            .field-wrapper {
                                width: 100%;
                            }

                            label {
                                span {
                                    white-space: nowrap;
                                }
                            }

                            span.error-icon {
                                margin-top: -0.5rem;
                            }

                            &:first-of-type {
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }

                .button-fancy-large {
                    padding-left: 3rem;
                    padding-right: 3rem;
                }
            }

            .manage-order {
                .modify-payment {
                    &:first-child {
                        -webkit-box-ordinal-group: 3;
                        -ms-flex-order: 2;
                        order: 2;
                    }

                    &:nth-child(2) {
                        -webkit-box-ordinal-group: 2;
                        -ms-flex-order: 1;
                        order: 1;
                    }

                    &:last-child {
                        -webkit-box-ordinal-group: 4;
                        -ms-flex-order: 3;
                        order: 3;
                    }
                }
            }
        }
    }

    .payment-list {
        li {
            .cc-expires {
                margin-left: -5rem;
                width: auto;
            }
        }
    }

    .account-summary-linked {
        .account-personalized-message {
            top: -2.5rem;
            left: -2.5rem;
            margin-bottom: -1.25rem;
            width: calc(100% + 2.5rem);
        }

        .account-plan-title {
            text-align: left;
        }

        .account-summary-items.account-plan-info {
            margin-top: 0.8125rem;
        }

        .account-summary-items {
            >div {
                width: 50%;
            }

            >div.account-plan-available-credit {
                width: 33.3333%;
                text-align: left;
                padding: 0 0.625rem;

                ~ {
                    div {
                        width: 33.3333%;
                        text-align: left;
                        padding: 0 0.625rem;

                        .value {
                            font-size: 20px;
                            font-size: 1.25rem;
                            padding-top: 0.4375rem;
                        }
                    }
                }
            }
        }
    }

    .account-module {
        form[id*="profile_addaccount"] {
            .field-wrapper {
                width: 22.5rem;
            }

            .postal {
                .field-wrapper {
                    width: 10rem;
                }
            }

            .form-row-button {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;

                .privacy-policy {
                    margin-left: 1.875rem;
                    margin-top: 0;
                }

                .remove-account {
                    margin-left: 1.875rem;
                    margin-top: 0;
                }
            }
        }

        .order-status {
            .form-row.postal {
                .field-wrapper {
                    max-width: 11.25rem;
                }
            }

            .form-row {
                &:not(.postal) {
                    .field-wrapper {
                        width: 22.5rem;
                    }
                }
            }
        }

        .account-module-row {
            .field-wrapper {
                max-width: 22.5rem;
            }
        }

        .account-module-list-items {
            .list-item {
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
            }
        }

        .account-module-button-row.buttons-row {
            .button {
                width: calc(50% - 15px);
                float: left;
                margin-right: 0.9375rem;
            }
        }

        .account-module-button-row {
            .button {
                max-width: 16.25rem;
            }
        }

        .transaction-detail {
            .account-module-list-items {
                .item {
                    width: calc(50% - 2.5rem);
                }

                .item.last {
                    width: 5rem;
                }
            }
        }
    }

    .account-module.full-width {
        .order-status {
            .form-row {
                float: left;
                margin-right: 1.875rem;

                &:not(.postal) {
                    width: 23%;
                }
            }

            .form-row.postal {
                width: 10rem;
                margin-right: 2.5rem;
            }

            button[name*="ordertrack_findorder"] {
                width: 20rem;
            }
        }
    }

    .change-plan {
        .button {
            max-width: 16.25rem;
        }
    }

    .order-history-item-summary {
        .order-amount {
            width: 17%;
        }

        .order-date {
            width: 17%;
        }

        .order-number {
            width: 17%;
        }
    }

    .order-details {
        .order-details-top {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;

            .order-actions {
                margin-left: auto;
                margin-top: 0;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                .button {
                    margin-bottom: 0;
                    width: 9.375rem;
                }

                button {
                    margin-bottom: 0;
                    width: 9.375rem;
                }

                .button.cancel-order {
                    margin-left: 1.25rem;
                }

                button.cancel-order {
                    margin-left: 1.25rem;
                }

                .down-payment {
                    button {
                        width: 16.25rem;
                    }

                    + {
                        .product-add-to-cart {
                            .button {
                                margin-left: 1.25rem;
                            }
                        }
                    }
                }
            }
        }

        .billing-message {
            padding-top: 1.25rem;
        }

        .order-shipments {
            padding-top: 1.25rem;
        }

        .shipping-address {
            .ship-to {
                width: 10rem;
                float: left;
            }

            .address {
                float: left;
                width: calc(100% - 10rem);
            }
        }

        .line-item {
            padding: 1.875rem 0;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .line-item-actions {
                width: 40%;
                width: 45%;
                text-align: right;

                .line-item-action {
                    .button {
                        width: 9.375rem;
                        float: right;
                    }
                }
            }

            .line-item-info {
                width: 40%;
                width: 33.3333%;
            }

            .line-item-status {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                width: 15%;
            }
        }
    }

    .order-history {
        .down-payment {
            button {
                width: 16.25rem;
            }
        }
    }

    .pt_content-search-result {
        #main {
            padding: 1.5625rem 0.9375rem 3.75rem;
        }

        .primary-content {
            padding-left: 2.5rem;
        }

        #secondary.refinements {
            display: block !important;
        }
    }

    .pt_product-search-noresult {
        #main {
            padding: 1.5625rem 0.9375rem 3.75rem;
        }
    }

    .pt_product-search-result {
        #main {
            padding: 1.5625rem 0.9375rem 3.75rem;
        }

        &:not(.pt_landing) {
            .primary-content {
                padding-left: 2.5rem;
            }

            #secondary.refinements {
                display: block !important;
            }
        }
    }

    #wrapper {
        #bottom-cat-banner-container {
            width: 53.6%;
            float: right;
            margin: 0 20.8em 0 0;
        }
    }

    .search-result-options.top-options {
        .pagination {
            width: calc(100% - 22.5rem);
        }
    }

    .refinement-select {
        display: none;
    }

    .items-per-page {
        display: block;
        margin-left: 0.625rem;
        max-width: 10.5rem;
    }

    .search-refinements-section {
        .refinement {
            width: 25%;

            &:nth-of-type(4n+1) {
                border-left: none;
            }
        }
    }

    .refinements-title {
        display: none;
    }

    .close-refinements {
        display: none;
    }

    .hero-container {
        .hero-content {
            position: absolute;
            padding: 0;
            margin: 120px;
            top: 0;
            left: 0;
            width: calc(100% - 240px);
            height: calc(100% - 240px);
        }
    }

    .hero-container.banner {
        .hero-content {
            margin: 60px 120px;
            width: calc(100% - 240px);
            height: calc(100% - 120px);
        }
    }

    .hero-container.third-split {
        .hero-content {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;

            .flex {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                flex-direction: row;
            }

            .split-item {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-item-align: center;
                align-self: center;
                box-sizing: border-box;
                text-align: left;

                &:first-child {
                    width: 66.6667%;
                }

                &:last-child {
                    width: 33.3333%;
                }

                .container {
                    text-align: left;

                    &:first-child {
                        margin-right: 1.25rem;
                        margin-bottom: 0;
                    }

                    &:nth-child(2) {
                        margin-left: 1.25rem;
                    }
                }
            }

            .split-item.first {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                padding-right: 5%;
            }

            .split-item.second {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                padding-left: 5%;
            }
        }
    }

    .hero-container.third-split.alternate {
        .hero-content {
            margin: 60px 100px;
            width: calc(100% - 200px);
            height: calc(100% - 120px);

            .split-item {
                h3 {
                    margin-top: 0;
                }

                &:first-child {
                    padding-right: 2%;
                }

                &:last-child {
                    padding-left: 2%;
                    text-align: left;
                }

                .container {
                    &:first-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .content-tiles {
        .content-tile-inner-wrapper {
            margin: 60px 30px;
        }
    }

    .top-categories-slot {
        padding-top: 4.375rem;
        padding-left: 0;
        padding-right: 0;

        .slot-header {
            padding-left: 0;
            padding-right: 0;
        }

        .top-categories-wrapper {
            padding: 0;
        }
    }

    .promo-banner {
        .promo-container {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;

            .promo-banner-background {
                content: "";
                height: 60%;
                width: 100%;
                bottom: 20%;
            }
        }

        .item1 {
            height: 7.5rem;
            width: 7.5rem;
            border-radius: 6em;
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
            margin: 0 1.5625rem;

            .callout-circle {
                height: 6.875rem;
                width: 6.875rem;
                border-radius: 6em;

                &::after {
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 10px solid #fff;
                }
            }

            .callout-circle-inner {
                span {
                    &:first-child {
                        font-family: $font-family_1;
                        font-weight: 900;
                        font-size: 18px;
                        font-size: 1.125rem;
                    }

                    &:nth-child(2) {
                        font-family: $font-family_1;
                        font-weight: 900;
                        font-size: 40px;
                        font-size: 2.5rem;
                    }

                    &:nth-child(3) {
                        font-family: $font-family_1;
                        font-weight: 900;
                        font-size: 18px;
                        font-size: 1.125rem;
                    }
                }
            }
        }

        .item2 {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
            padding: 0;
            font-family: $font-family_1;
            font-size: 31px;
            font-size: 1.9375rem;
            font-size: 36px;
            font-size: 2.25rem;
        }

        .item3 {
            -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
            order: 3;
            padding: 0;
            font-family: $font-family_1;
            font-size: 31px;
            font-size: 1.9375rem;
            font-size: 36px;
            font-size: 2.25rem;
        }
    }

    .mobile-category-buttons {
        display: none;
    }

    .header-top-promo {
        height: 3.125rem;
        line-height: 50px;
        line-height: 3.125rem;

        .primary-cta {
            font-size: 28px;
            font-size: 1.75rem;
            letter-spacing: 0.4px;

            span {
                &:first-of-type {
                    padding-right: 0.625rem;
                }
            }
        }
    }

    .footer-top-promo {
        height: 5rem;
        line-height: 80px;
        line-height: 5rem;

        .primary-cta {
            font-size: 30px;
            font-size: 1.875rem;
        }

        img {
            max-width: 5.625rem;
            margin-top: 0.8125rem;
        }
    }

    .header-bottom-promo {
        height: 2.5rem;
        line-height: 40px;
        line-height: 2.5rem;
    }

    .header-bottom-promo.bottom-asset {
        .primary-cta {
            font-family: $font-family_1;
            font-weight: 900;
            font-size: 22px;
            font-size: 1.375rem;
        }
    }

    .type-hero {
        font-size: 70px;
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 30px;
    }

    .h2-alt {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 20px;
    }
}

@media (min-width: 480px) {
    .type-hero {
        font-size: 55px;
        font-size: 3.4375rem;
    }

    .horizontal-carousel-with-arrows {
        .slick-slide {
            width: 44%;
        }
    }

    .horizontal-carousel {
        .slick-slide {
            width: 44%;
        }
    }

    .content-asset {
        .account-options {
            li {
                width: 41%;
                -ms-flex-preferred-size: 41%;
                flex-basis: 41%;
                margin: 1rem;
            }
        }
    }

    .store-tile {
        width: 45%;
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    }

    .checkout-shipping {
        .select-style {
            width: 40%;
        }
    }

    .ship-to-multiple {
        .radio {
            p {
                width: 40%;
            }
        }
    }

    .shipping-gift {
        .radio {
            p {
                width: 40%;
            }
        }
    }

    .content-width {
        .account-payment-mailing-address {
            display: -webkit-box;
            display: flexbox;
            display: flex;
        }
    }

    .search-result-items {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .grid-tile {
            width: 50%;
        }
    }

    .folder-content-list {
        li {
            width: calc(50% - 0.625rem);
            -ms-flex-preferred-size: 40%;
            flex-basis: 40%;
            vertical-align: top;
        }
    }
}

@media (max-width: 1279px) {
    .type-asset-tile-heading-outer {
        letter-spacing: 0.2px;
    }

    #add-to-cart {
        letter-spacing: 0.2px;
    }

    .button-fancy-large {
        letter-spacing: 0.2px;
    }

    .h1 {
        letter-spacing: 0.2px;
    }

    h1 {
        letter-spacing: 0.2px;
    }

    .h4 {
        letter-spacing: 0.2px;
    }

    h4 {
        letter-spacing: 0.2px;
    }

    .ui-dialog {
        .ui-dialog-buttonpane {
            .ui-dialog-buttonset {
                .button.large {
                    span {
                        letter-spacing: 0.2px;
                    }
                }
            }
        }
    }

    .pdp-main {
        .product-name {
            letter-spacing: 0.2px;
        }

        .installments-product-callout {
            letter-spacing: 0.2px;
        }

        .monthly-product-price {
            letter-spacing: 0.2px;
        }

        .pnc {
            .pnc-product {
                .pnc-product-info {
                    .pnc-name {
                        letter-spacing: 0.2px;
                    }
                }
            }
        }

        .promotion {
            .promotion-callout {
                letter-spacing: 0.2px;
            }
        }
    }

    .pt_cart {
        .cart-order-totals {
            .order-total {
                letter-spacing: 0.2px;
            }
        }
    }

    .checkoutmultishipping {
        .section-header {
            letter-spacing: 0.2px;
        }

        .item-list-heading {
            .section-header {
                letter-spacing: 0.2px;
            }
        }
    }

    .checkoutplaceorder {
        .section-header {
            letter-spacing: 0.2px;
        }

        .item-list-heading {
            .section-header {
                letter-spacing: 0.2px;
            }
        }
    }

    .orderdetails-checkout {
        .section-header {
            letter-spacing: 0.2px;
        }

        .item-list-heading {
            .section-header {
                letter-spacing: 0.2px;
            }
        }

        .order-information {
            margin-bottom: 0;
        }

        .order-receipt-summary {
            padding-top: 1.875rem;
        }
    }

    .address-modal {
        .ui-dialog-titlebar {
            letter-spacing: 0.2px;
        }
    }

    .summary {
        .need-help-asset {
            span {
                &:last-child {
                    letter-spacing: 0.2px;
                }
            }
        }
    }

    .checkout-shipping {
        .section-header {
            letter-spacing: 0.2px;
        }
    }

    .shipping-methods {
        legend {
            letter-spacing: 0.2px;
        }
    }

    #main.registered {
        .account-module.manage-credit-card {
            h4 {
                letter-spacing: 0.2px;
            }
        }

        .account-module.ag-account-registration {
            .section-header {
                letter-spacing: 0.2px;
            }
        }
    }

    #main:not(.registered) {
        #accountGuardPage {
            .account-module.manage-credit-card {
                h4 {
                    letter-spacing: 0.2px;
                }
            }

            .account-module.ag-account-registration {
                .section-header {
                    letter-spacing: 0.2px;
                }
            }
        }
    }

    .account-registration {
        h2 {
            letter-spacing: 0.2px;
        }
    }

    .createbenefits {
        h3 {
            letter-spacing: 0.2px;
        }
    }

    .hero-container {
        .hero-content.stacked-banner {
            .hero-content-inner {
                width: 100%;
                position: relative;
            }
        }
    }

    .hero-container.side-by-side {
        .hero-content {
            position: absolute;
            padding: 0;
            margin: 1.25rem 1.25rem 1.875rem;
            top: 0;
            left: 0;
            width: calc(100% - 40px);
            height: calc(100% - 50px);

            .call-text {
                .type-hero {
                    font-size: 40px;
                    font-size: 2.5rem;
                }
            }
        }

        picture {
            >img {
                height: fit-content;
            }
        }
    }

    .hero-container.third-split {
        .hero-content {
            position: absolute;
            padding: 0;
            margin: 1.25rem 1.25rem 1.875rem;
            top: 0;
            left: 0;
            width: calc(100% - 40px);
            height: calc(100% - 50px);
            text-align: left;

            .call-text {
                .type-hero {
                    font-size: 40px;
                    font-size: 2.5rem;
                }
            }

            .center {
                text-align: center !important;
            }
        }

        img {
            max-height: fit-content;
        }
    }

    .content-tiles.outer-content {
        h3 {
            letter-spacing: 0.2px;
        }
    }
}

@media (-ms-high-contrast: none) {
    .account-menu-select {
        padding-right: 10px;
    }

    .items-per-page {
        fieldset {
            padding-right: 10px;
        }
    }

    .refinement-select {
        padding-right: 10px;
    }

    .select-style {
        padding-right: 10px;
    }

    .sort-by {
        fieldset {
            padding-right: 10px;
        }
    }
}

@media screen and (-ms-high-contrast: active) {
    .account-menu-select {
        padding-right: 10px;
    }

    .items-per-page {
        fieldset {
            padding-right: 10px;
        }
    }

    .refinement-select {
        padding-right: 10px;
    }

    .select-style {
        padding-right: 10px;
    }

    .sort-by {
        fieldset {
            padding-right: 10px;
        }
    }
}

@media (min-width: 0\0)and (min-resolution: 0) {
    .account-menu-select {
        padding-right: 0;

        select {
            padding-right: 10px;
        }
    }

    .items-per-page {
        fieldset {
            padding-right: 0;

            select {
                padding-right: 10px;
            }
        }
    }

    .refinement-select {
        padding-right: 0;

        select {
            padding-right: 10px;
        }
    }

    .select-style {
        padding-right: 0;

        select {
            padding-right: 10px;
        }
    }

    .sort-by {
        fieldset {
            padding-right: 0;

            select {
                padding-right: 10px;
            }
        }
    }
}

@media all and (min-width: 0\0)and (min-resolution: 0.001dpcm) {
    .account-menu-select {
        padding-right: 0;

        select {
            padding-right: 10px;
        }
    }

    .items-per-page {
        fieldset {
            padding-right: 0;

            select {
                padding-right: 10px;
            }
        }
    }

    .refinement-select {
        padding-right: 0;

        select {
            padding-right: 10px;
        }
    }

    .select-style {
        padding-right: 0;

        select {
            padding-right: 10px;
        }
    }

    .sort-by {
        fieldset {
            padding-right: 0;

            select {
                padding-right: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1025px) {
    nav[role="navigation"] {
        padding: 0 5%;
    }

    #wrapper.menu-active {
        .menu-overlay {
            display: none;
        }
    }

    .mobile-menu-header {
        display: none;
    }

    .back-link {
        display: none !important;
    }

    .see-all-link {
        display: none !important;
    }

    .menu-category-tiles {
        display: -webkit-box;
        display: flexbox;
        display: flex;
        flex-wrap: wrap;
        width: 100% !important;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .menu-category-tile {
        width: 25%;
        padding: 0 20px 25px;

        img {
            display: block;
            width: 100%;
        }
    }

    .sub-nav-content-asset {
        .sub-nav-content-container {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            padding: 35px 0 50px;
        }

        .sub-nav-content-column {
            padding-right: 25px;
        }

        .sub-nav-content-item {
            padding-bottom: 35px;

            img {
                display: block;
            }
        }

        .sub-nav-content-item.text-content {
            padding-left: 20px;
            border-left: 1px solid #393939;

            li {
                >a {
                    &:hover {
                        text-decoration: none;
                        cursor: default;
                    }
                }
            }
        }
    }

    .menu-category {
        width: 100%;
        line-height: 1.2;
        display: -webkit-box;
        display: flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 100%;

        a {
            display: none;
        }

        .arrow {
            display: none;
        }
    }

    .mobile-menu-utility-user {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 100%;
        display: none;

        a {
            display: none;
        }

        .arrow {
            display: none;
        }
    }

    .mobile-cap {
        display: none;
    }

    .mobile-menu-level-label.show-menu-item {
        display: none;
    }

    .level-1 {
        >li {
            height: 100%;

            >a.has-sub-menu {
                &::after {
                    content: "";
                    display: none;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    transform: rotate(45deg);
                    bottom: -30px;
                    left: 6px;
                    box-shadow: -4px -4px 20px -5px rgb(0 0 0 / 25%);
                    z-index: 100;
                }

                &::before {
                    content: "";
                    display: none;
                    position: absolute;
                    width: 200%;
                    height: 40px;
                    bottom: -35px;
                    background: transparent;
                }
            }

            >a {
                display: -webkit-box;
                display: flexbox;
                display: flex;
                position: relative;
                height: 100%;
                color: $color_2;
                margin: 0 12px;
                text-align: center;

                span.break-name {
                    line-height: 1.2;
                    text-align: center;

                    br {
                        display: block;
                    }
                }
            }
        }

        >li.navActive {
            .level-2 {
                display: block;
            }

            >a {
                &::after {
                    display: block;
                }

                &::before {
                    display: block;
                }
            }
        }
    }

    .level-2 {
        display: none;
        position: absolute;
        z-index: 99;
        padding: 0;
        margin-top: 20px;
        box-shadow: 0 7px 22px 0 rgb(0 0 0 / 20%);

        a {
            color: $color_2;
            line-height: 1.2;
            margin-bottom: 0.625rem;

            &:hover {
                text-decoration: underline;
            }
        }

        .banner {
            width: 28.8%;
            display: block;
        }

        .horizontal-banner {
            display: block;
        }
    }

    .menu-columns {
        display: -webkit-box;
        display: flexbox;
        display: flex;
        padding: 1.875rem 1.875rem 1.25rem;
        margin: auto;
        width: intrinsic;
        width: max-content;
        width: max-content;
        width: max-content;
        max-width: calc(100vw - 5rem);
    }

    .menu-vertical {
        padding-right: 20px;
        padding-left: 20px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-left: 0;
        }

        &:last-of-type {
            border-right: none;
        }

        >li {
            &:not(.menu-category-tile) {
                margin-top: 1.5625rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .level-3 {
        a {
            color: $color_2;
        }
    }

    .menu-utility-user {
        display: -webkit-box;
        display: flexbox;
        display: flex;

        .user-info {
            display: -webkit-box;
            display: flexbox;
            display: flex;
        }

        .account {
            margin-right: 0.625rem;
        }

        .arrow {
            margin-left: 0.625rem;
        }

        .user-account {
            display: -webkit-box;
            display: flexbox;
            display: flex;

            .welcome-container {
                display: -webkit-box;
                display: flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;

                .welcome-user {
                    font-size: 11px;
                    font-size: 0.6875rem;
                    line-height: 0.75em;
                    text-align: left;
                }

                .account-message {
                    line-height: 1.15em;
                }
            }
        }
    }

    .navigation-mobile-backing {
        display: none;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
    .menu-tile {
        &:nth-child(4n) {
            border: none;
        }

        &:nth-child(n+5) {
            margin-top: 20px;
        }
    }

    .menu-category-tile {
        &:nth-child(n+5) {
            margin-top: 20px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .menu-tile {
        width: 20%;

        &:nth-child(5n) {
            border: none;
        }

        &:nth-child(n+6) {
            margin-top: 20px;
        }
    }

    .menu-category-tile {
        width: 20%;

        &:nth-child(n+6) {
            margin-top: 20px;
        }
    }

    .menu-columns {
        max-width: 1380px;
    }

    .top-banner {
        .menu-columns {
            box-sizing: unset;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .account-menu-toggle {
        float: right;
        margin-right: 12%;
    }

    .menu-category {
        width: 100%;

        a {
            padding: 1.125rem 0;
            margin: 0 1.25rem;
            width: calc(100% - 40px);

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $background-color_8;
            }
        }
    }

    .mobile-menu-utility-user {
        width: 100%;

        a {
            padding: 1.125rem 0;
            margin: 0 1.25rem;
            width: calc(100% - 40px);

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $background-color_8;
            }
        }
    }

    .menu-columns {
        padding-left: 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mobile-cap {
        .close {
            margin-left: 0;
        }
    }

    span.email-signup-msg {
        float: right;
        width: 23.75rem;
        box-sizing: border-box;
    }

    .payment-list {
        li {
            .cc-expires {
                width: 30%;
            }

            .cc-type-number {
                width: 30%;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
    .level-1 {
        >li {
            a {
                display: none;
            }

            a.show-menu-item {
                display: block;
                position: relative;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    table {
        .section-header {
            font-size: 1em !important;
        }
    }

    .ui-tabs {
        .ui-tabs-panel {
            h3 {
                display: none;
            }
        }
    }

    .html-slot-container {
        img {
            max-width: 100%;
        }
    }

    #cart-table {
        .price-promotion {
            span {
                display: block;
            }

            span.not-available.all-not-available {
                display: none;
            }
        }
    }

    .account-module {
        .item-list {
            .price-promotion {
                span {
                    display: block;
                }

                span.not-available.all-not-available {
                    display: none;
                }
            }
        }
    }

    .search-refinements-section {
        .refinement {
            &:nth-of-type(3n+1) {
                border-left: none;
            }
        }
    }

    .bottom-banner-container {
        .bottom-banner-cell {
            img {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .width-third {
        max-width: 33.33%;
        width: 33.33%;
        display: inline-block;
    }

    .width-twothird {
        max-width: 66.66%;
        width: 66.66%;
        display: inline-block;
    }

    .width-half {
        max-width: 50%;
        width: 50%;
        display: inline-block;
    }

    .product-tile {
        .product-image.rollover.hover {
            .thumb-link {
                img.thumb-front {
                    opacity: 0;
                }

                img.thumb-back {
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 1024px) and (max-width: 1024px) {
    .carousel-wrapper-a2c-confirmation {
        .grid-tile {
            width: 20.75rem !important;
            margin: 0.3125rem 1.4375rem;
        }
    }
}

@media print {
    .account-nav-registered {
        display: none;
    }

    .copyright {
        display: none;
    }

    .header-banner-bottom {
        display: none;
    }

    .header-banner-top {
        display: none;
    }

    .print-hidden {
        display: none;
    }

    .pt_order-confirmation {
        #mini-cart {
            display: none;
        }

        #navigation {
            display: none;
        }

        .header-banner {
            display: none;
        }

        .menu-utility {
            display: none;
        }

        .menu-utility-user {
            display: none;
        }
    }

    .top-banner {
        display: none;
    }

    footer {
        display: none;
    }
}

@media (min-width: 1280px) {
    .pdp-main {
        .product-add-to-cart {
            .AddToCartAlertMessage {
                text-align: left;
            }

            .leadTimeMsg {
                text-align: left;
                margin-left: 160px;
            }

            .add-to-cart-wrap {
                width: calc(100% - 10rem);
            }
        }
    }

    .product-set-list {
        .product-set-item {
            .add-to-cart-wrap {
                width: auto;
                margin-left: 30px;
            }
        }
    }

    button#product-set-add-to-cart {
        width: 280px;
    }

    #QuickViewDialog {
        .product-add-to-cart {
            .AddToCartAlertMessage {
                margin-left: 160px;
            }
        }
    }

    .account-module {
        .make-payment.make-payment-include {
            width: 16.25rem;
            float: left;

            + {
                .content-asset {
                    float: right;
                    margin-top: 0;
                    width: 16.25rem;
                    box-sizing: border-box;
                    min-height: 15.875rem;
                }
            }
        }
    }

    .search-result-options.top-options {
        .pagination {
            ul {
                margin: 0.625rem 0;

                + {
                    .results-hits {
                        float: left;
                        text-align: left;
                        margin: 0.625rem 0;
                    }
                }
            }
        }
    }

    .pagination {
        .results-hits {
            clear: none;
        }
    }

    .hero-container {
        .hero-content-inner {
            .type-hero {
                font-size: 72px;
                font-size: 4.5rem;
            }

            h3 {
                font-size: 24px;
                font-size: 1.5rem;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .checkout-progress-indicator {
        letter-spacing: 0.2px;
    }
}

@media (min-width: 768px) and (min-width: 768px) {
    .checkout-progress-indicator {
        font-size: 21px;
        font-size: 1.3125rem;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .select-payment-methods {
        h4 {
            font-size: 25px;
            font-size: 1.5625rem;
        }
    }

    .payment-method {
        .checkout-form-section-header {
            >h4 {
                font-size: 21px;
                font-size: 1.3125rem;
            }
        }
    }
}

@media (max-width: 1024px) and (max-width: 1279px) {
    .payment-method {
        .checkout-form-section-header {
            >h4 {
                letter-spacing: 0.2px;
            }
        }
    }
}

@media (max-width: 1024px) and (min-width: 480px) {
    .hero-container {
        .hero-content-inner {
            &:not(.mobile-content-inside) {
                .button {
                    width: auto;
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1279px) {
    .promo-banner {
        .item2 {
            letter-spacing: 0.2px;
        }

        .item3 {
            letter-spacing: 0.2px;
        }
    }
}

@media (min-width: 1025px) and (min-width: 768px) {
    .promo-banner {
        .item2 {
            font-size: 41px;
            font-size: 2.5625rem;
        }

        .item3 {
            font-size: 41px;
            font-size: 2.5625rem;
        }
    }
}

@media screen and (min-width: 480px) {
    .br-l {
        display: none;
    }

    .br-s {
        display: none;
    }

    .br-xl {
        display: none;
    }

    .br-m {
        display: block;
    }
}

@media screen and (min-width: 768px) {
    .br-m {
        display: none;
    }

    .br-s {
        display: none;
    }

    .br-xl {
        display: none;
    }

    .br-l {
        display: block;
    }
}

@media screen and (min-width: 1025px) {
    .br-l {
        display: none;
    }

    .br-m {
        display: none;
    }

    .br-s {
        display: none;
    }

    .br-xl {
        display: block;
    }
}
